import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStores } from "../../hooks/use-stores";
import { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import OrderChat from "../../components/Order/OrderChat";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Order = () => {
  const [openSnack, setOpenSnack] = useState(false);
  const [snackText, setSnackText] = useState("");
  const { orders, messages } = useStores();
  const { getOrder, order, updateOrderStatus, setOrdersList, updateOrder, loading, attachFiles } = orders;
  const { getMessages, messagesList, sendMessage, setMessages } = messages;
  const { id } = useParams();
  const handleCloseSnack = () => setOpenSnack(false);

  useEffect(() => {
    getOrder(id);
    getMessages(id);

    const interval = setInterval(() => {
      getMessages(id);
    }, 3000);
    return () => {
      clearInterval(interval);
      setMessages([]);
    };
  }, []);

  const updateStatus = async (status) => {
    try {
      const text = await updateOrderStatus(id, status);
      setSnackText(text);
      setOpenSnack(true);
    } catch (e) {
      setSnackText(e.message);
      setOpenSnack(true);
    }
  };

  const onFilesAttach = async (files) => {
    try {
      const msg = await attachFiles(id, files);
      await getOrder(id);
      setSnackText(msg);
      setOpenSnack(true);
    } catch (e) {
      setSnackText(e.message);
      setOpenSnack(true);
    }
  };

  const onOrderUpdateOrder = async (data) => {
    try {
      const msg = await updateOrder(id, data);
      setSnackText(msg);
      setOpenSnack(true);
    } catch (e) {
      setSnackText(e.message);
      setOpenSnack(true);
    }
  };

  const createMessage = async (msg, file) => {
    const payload = {};
    if (msg) {
      payload["message"] = msg;
      payload["order"] = {
        id: +id,
      };
      await sendMessage(payload);
      await getMessages(id);
      return;
    }
    if (file) {
      payload["message"] = file.name;
      payload["order"] = {
        id: +id,
      };
      payload["fileId"] = file.id;
      await sendMessage(payload);
      await getMessages(id);
      await getOrder(id);
    }
  };

  const onClearChat = () => {
    setOrdersList([]);
  };

  return (
    <div>
      <OrderChat
        orderInfo={order}
        messages={messagesList}
        onMessageSend={createMessage}
        onStatusUpdate={updateStatus}
        clearChat={onClearChat}
        onUpdate={onOrderUpdateOrder}
        isLoading={loading}
        onFilesAdd={onFilesAttach}
      />
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {snackText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(Order);
