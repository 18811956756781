import React, { Fragment, useEffect } from "react";
import { toJS } from "mobx";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  Snackbar,
  Alert,
  Typography,
  TextField,
  Grid,
  Box,
  Button,
  Modal,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 2,
};

const HandleEditStaffForm = ({ onDataSend, loading, error, data, onDataRemove, success, clearSuccess }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [role, setRole] = React.useState("ROLE_STAFF");
  const handleOpenSnack = () => setOpenSnack(true);
  const handleCloseSnack = () => {
    setOpenSnack(false);
    clearSuccess(null);
  };

  useEffect(() => {
    if (success) {
      handleOpenSnack();
    }
  }, [success]);

  useEffect(() => {
    return () => {
      clearSuccess(null);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(1, "Name must be at least 1 characters")
      .max(100, "Name must not exceed 100 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    role: Yup.string()
      .required("Role is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    onDataSend(data);
  };

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onStaffRemove = () => {
    const { id } = toJS(data);
    onDataRemove(id);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const changeCategory = (e) => {
    setRole(e.target.value);
  };

  const roles = [
    {
      value: "ROLE_STAFF",
      label: "Staff",
    },
    {
      value: "ROLE_ADMIN",
      label: "Admin",
    },
  ];

  return (
    <Fragment>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            {data.name && (
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                margin="dense"
                defaultValue={data.name}
                {...register("name")}
                error={errors.name ? true : false}
              />
            )}
            <Typography variant="inherit" color="textSecondary">
              {errors.username?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            {data.email && (
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                margin="dense"
                defaultValue={data.email}
                {...register("email")}
                error={errors.email ? true : false}
              />
            )}
            <Typography variant="inherit" color="textSecondary">
              {errors.email?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-select-role"
              select
              placeholder="Select role"
              label="Select role"
              variant="standard"
              error={!!errors.role}
              margin="dense"
              {...register("role", {
                onChange: (e) => changeCategory(e),
              })}
              value={role}
              fullWidth
            >
              {roles.map((option) => (
                <MenuItem sx={{ width: "100%" }} key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Box
          mt={0}
          sx={{ padding: 0 }}
        >
          {!data.email && !data.name && (
            <Alert
              severity="error"
              sx={{ "mt": 2 }}
            >
              Staff has not been found
            </Alert>
          )}
          {data.email && data.name && (
            <>
              <Button
                sx={{
                  "mt": 2,
                  "width": "100%",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "black",
                    width: "100%",
                  } }}
                size="medium"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
              >Update
              </Button>
              <Button
                sx={{
                  "mt": 2,
                  "width": "100%",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "black",
                    width: "100%",
                  } }}
                color="error"
                size="medium"
                variant="contained"
                disabled={loading}
                onClick={onModalOpen}
              >Remove staff
              </Button>
            </>
          )}

        </Box>
        { error && (
          <Alert
            severity="error"
            sx={{ "mt": 2 }}
          >
            {error}
          </Alert>
        )}
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p>Are you sure you want to remove this staff?</p>
          <Box sx={{
            padding: 0,
          }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleModalClose}
              sx={{
                "marginRight": "7px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
              onClick={onStaffRemove}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default HandleEditStaffForm;

HandleEditStaffForm.propTypes = {
  onDataSend: PropTypes.func,
  clearSuccess: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.any,
  success: PropTypes.any,
  type: PropTypes.string,
  data: PropTypes.object,
  onDataRemove: PropTypes.func,
};
