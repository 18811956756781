export default {
  userList: {
    path: "/user-management/user",
    method: "get",
  },
  removeUser: {
    path: "/user-management/user/:id",
    method: "delete",
  },
  updateUser: {
    path: "/user-management/user/:id",
    method: "patch",
  },
  getClient: {
    path: "/user-management/user/:id",
    method: "get",
  },
  getKYCForm: {
    path: "/user-management/kyc",
    method: "get",
  },
  updateKYC: {
    path: "/user-management/kyc/:id",
    method: "patch",
  },
  PAN: {
    method: "get",
    path: "/user-management/kyc/company-house",
  },
  downloadUserList: {
    method: "post",
    path: "/user-management/user/export",
  },
};
