import { observable, action, computed, makeObservable } from "mobx";
import { axios } from "../utils/axios";
import clients from "../api/endpoints/clients";
import downloadFile from "../utils/downloadFile";

class Clients {
  usersList = []
  paginationLimit = 15
  currentPage = 1
  metadata = {}
  loading = false
  success = null
  user = {}
  KYCFormData = {}
  error = null
  PANCompanies = []

  async getUserList(filter) {
    const path = clients.userList.path;
    const method = clients.userList.method;
    this.setLoading(true);
    try {
      const data = await axios[method](path, {
        params: {
          offset: this.offset,
          limit: this.paginationLimit,
          ...filter,
        },
      });
      const { metadata, results } = data;
      this.setMetadata(metadata);
      this.setUserList(results);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async getPANCompanies(data) {
    const method = clients.PAN.method;
    const path = clients.PAN.path;

    try {
      const resp = await axios[method](path, {
        params: {
          "filter[search]": data,
          "limit": 10,
        },
      });
      this.setPANCompanies(resp.results);
    } catch (e) {
      console.error(e);
    }
  }

  setPANCompanies(data) {
    this.PANCompanies = data;
  }

  async getKYCFormData(filterId) {
    const path = clients.getKYCForm.path;
    const method = clients.getKYCForm.method;

    this.setLoading(true);
    try {
      const { results } = await axios[method](path, {
        params: {
          "filter[user]": filterId,
        },
      });
      if (results.length) {
        this.setKYCForm(results[0]);
      } else {
        this.setKYCForm({});
      }
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async updateKYC(kycId, payload, isUpdate) {
    const path = clients.updateKYC.path;
    const method = clients.updateKYC.method;
    const pathID = path.replace(":id", kycId);

    this.setLoading(true);
    this.setError(null);
    this.clearSuccess();
    try {
      const data = await axios[method](pathID, payload);
      this.setKYCForm(data);
      if (isUpdate) {
        this.setSuccess("Client details has been successfully updated");
        return;
      }
      if (payload.status) {
        this.setSuccess("User have been successfully approved");
      } else {
        this.setSuccess("Company details have been successfully updated");
      }
    } catch (e) {
      console.error(e);
      this.setError(e.message);
    }
    this.setLoading(false);
  }

  async removeUser(clientId) {
    const path = clients.removeUser.path;
    const method = clients.removeUser.method;
    const pathID = path.replace(":id", clientId);

    this.setLoading(true);
    try {
      await axios[method](pathID);
      this.setSuccess("User was successfully removed");
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async getUser(clientId) {
    const path = clients.getClient.path;
    const method = clients.getClient.method;
    const pathID = path.replace(":id", clientId);

    this.setLoading(true);
    try {
      const data = await axios[method](pathID);
      this.setUser(data);
    } catch (e) {
      console.error(e);
      this.setUser({});
    }
    this.setLoading(false);
  }

  async updateUser(clientId, payload) {
    const path = clients.updateUser.path;
    const method = clients.updateUser.method;
    const pathID = path.replace(":id", clientId);

    this.setLoading(true);
    try {
      await axios[method](pathID, payload);
      this.setSuccess("User was successfully updated");
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async downloadUserlist() {
    const path = clients.downloadUserList.path;
    const method = clients.downloadUserList.method;

    try {
      const url = await axios[method](path);
      const file = {
        name: path,
        type: "csv",
        url,
      };
      downloadFile(file);
    } catch (e) {
      console.error(e);
    }
  }

  setError(err) {
    this.error = err;
  }

  setKYCForm(form) {
    this.KYCFormData = form;
  }

  clearSuccess() {
    this.success = null;
  }

  setSuccess(suc) {
    this.success = suc;
  }

  setUser(client) {
    this.user = client;
  }

  setMetadata(data) {
    this.metadata = data;
  }

  setUserList(list) {
    this.usersList = list;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  getNextPage(page, filter) {
    this.setCurrentPage(page);
    this.getUserList(filter);
  }

  get totalPages() {
    if (this.metadata.total) {
      return Math.ceil(this.metadata.total / this.metadata.limit);
    }
  }

  get offset() {
    return (this.currentPage - 1) * this.paginationLimit;
  }
}

const clientsStore = new Clients();

makeObservable(clientsStore, {
  usersList: observable,
  user: observable,
  success: observable,
  error: observable,
  currentPage: observable,
  paginationLimit: observable,
  metadata: observable,
  PANCompanies: observable,
  KYCFormData: observable,
  loading: observable,
  getUserList: action.bound,
  getNextPage: action.bound,
  removeUser: action.bound,
  setSuccess: action.bound,
  setUserList: action.bound,
  getUser: action.bound,
  setUser: action.bound,
  setMetadata: action.bound,
  setCurrentPage: action.bound,
  setError: action.bound,
  clearSuccess: action.bound,
  setLoading: action.bound,
  updateKYC: action.bound,
  updateUser: action.bound,
  getKYCFormData: action.bound,
  getPANCompanies: action.bound,
  setPANCompanies: action.bound,
  totalPages: computed,
});

export default clientsStore;
