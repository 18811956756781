export default {
  orderList: {
    path: "/order",
    method: "get",
  },
  viewOrder: {
    path: "/order/:id",
    method: "get",
  },
  removeOrder: {
    path: "/order/:id",
    method: "delete",
  },
  updateOrderStatus: {
    path: "/order/order_id/switch-status",
    method: "patch",
  },
  updateOrder: {
    path: "/order/:id",
    method: "patch",
  },
  attachFiles: {
    path: "/order/:id/attach-file",
    method: "patch",
  },
};
