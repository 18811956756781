import EditStaff from "../../components/StaffComponent/components/EditStaff";

const EditStaffView = () => {
  return (
    <div>
      <EditStaff />
    </div>
  );
};

export default EditStaffView;
