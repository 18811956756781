import { observable, action, makeObservable } from "mobx";
import { axios } from "../utils/axios";
import messages from "../api/endpoints/messsages";

class Messages {
  messagesList = []
  notifications = {};

  async getNotifications() {
    const path = messages.notifications.path;
    const method = messages.notifications.method;

    try {
      const data = await axios[method](path);
      this.setNotifications(data);
    } catch (e) {
      console.error(e);
    }
  }

  async bulkRead(orderId, msgs) {
    const path = messages.bulkRead.path;
    const method = messages.bulkRead.method;

    try {
      await axios[method](path, {
        orderId,
        messages: msgs,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async getMessages(orderId) {
    const path = messages.messagesList.path;
    const method = messages.messagesList.method;

    try {
      const { results } = await axios[method](path, {
        params: {
          "filter[order]": orderId,
          "limit": 10000,
          "sortField": "createdAt",
          "sortDirection": "DESC",
        },
      });
      this.setMessages(results);
      const unreadedMessagesIds = this.getUnreadedIds(results);
      if (unreadedMessagesIds.length) {
        await this.bulkRead(+orderId, unreadedMessagesIds);
        await this.getNotifications();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async sendMessage(payload) {
    const path = messages.creatMessage.path;
    const method = messages.creatMessage.method;

    try {
      await axios[method](path, {
        ...payload,
      });
      return Promise.resolve();
    } catch (e) {
      console.error(e);
    }
  }

  setMessages(messages) {
    this.messagesList = messages;
  }

  setNotifications(notifications) {
    this.notifications = notifications;
  }

  getUnreadedIds(msgs) {
    const ids = [];
    for (const msg of msgs) {
      if (!msg.isRead && msg.user) {
        ids.push(msg.id);
      }
    }
    return ids;
  }
}

const messagesStore = new Messages();

makeObservable(messagesStore, {
  messagesList: observable,
  notifications: observable,
  getMessages: action.bound,
  setMessages: action.bound,
  getNotifications: action.bound,
  bulkRead: action.bound,
  setNotifications: action.bound,
  getUnreadedIds: action.bound,
});

export default messagesStore;
