import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import CatalogItem from "../../components/CatalogItem/CatalogItem";
import style from "./catalog.module.scss";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import * as React from "react";
import FolderOpenSharpIcon from "@mui/icons-material/FolderOpenSharp";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileUploader from "../../components/FileUploader/FileUploader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useStores } from "../../hooks/use-stores";
import { useState, useEffect } from "react";
import FileReader from "../../components/FileReader/FileReader";
import Modal from "@mui/material/Modal";
import { TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toJS } from "mobx";
import fi from "date-fns/esm/locale/fi/index.js";
const qs = require("query-string");
/* eslint-disable */

const Catalog = () => {
  const { catalog } = useStores();
  const { file } = useStores();
  // const progress = fileStore;
  const { catalogList, createCatalog, viewCatalog, breadCrumbsReversed, getCatalogItem, catalogItem, removeCatalog, updateCatalog } = catalog;
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [itemToCreate, setItemToCreate] = useState("");
  const [fileItem, setFile] = useState({});
  const [fileError, setFileError] = useState(false);
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(1, "Name must be at least 1 characters")
      .max(100, "Name must not exceed 100 characters"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const history = useHistory();

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    setOpenLoader(true);
    getCatalogItem(queryParams["filter[parent]"]);
    viewCatalog(queryParams)
      .finally(() => {
        setOpenLoader(false);
      });
  }, []);

  const onRemoveFile = async () => {
    setFile({});
    file.setProgress(0);
  };

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    setOpenLoader(true);
    getCatalogItem(queryParams["filter[parent]"]);
    setSelected(false);
    viewCatalog(queryParams)
      .finally(() => {
        setOpenLoader(false);
      });
  }, [location]);

  const onSubmit = async (data) => {
    const queryParams = qs.parse(location.search);
    const parentId = queryParams["filter[parent]"];
    setFileError(false);
    file.setProgress(0);
    if (itemToCreate === "File" && !fileItem.id) {
      setFileError(true);
      return;
    }
    if (selected.id) {
      setOpenLoader(true);
      if (fileItem.id) {
        data.fileId = fileItem.id;
      }
      const queryParams = qs.parse(location.search);
      setEditModalOpen(false);
      try {
        await updateCatalog(selected.id, data);
        viewCatalog(queryParams)
          .finally(() => {
            setOpenLoader(false);
          });
      } catch (e) {
        console.error(e);
      }
      getCatalogItem(queryParams["filter[parent]"]);
      setOpenLoader(false);
      setSelected({});
      return;
    }
    switch (itemToCreate) {
    case "File":
      setEditModalOpen(false);
      setItemToCreate("File");
      setSelected({});
      reset({});
      const payload = {
        name: data.name,
        type: "file",
        parentId: +parentId,
        fileId: fileItem.id,
      };
      setOpenLoader(true);
      createCatalog(parentId, payload)
        .then(() => {
          viewCatalog(queryParams);
        })
        .finally(() => {
          setOpenLoader(false);
          setFile({});
        });
      break;
    case "Category":
      setEditModalOpen(false);
      const pld = {
        name: data.name,
        type: "category",
        parentId: +parentId,
      };
      setOpenLoader(true);
      createCatalog(parentId, pld)
        .then(() => {
          viewCatalog(queryParams);
        })
        .finally(() => {
          setOpenLoader(false);
        });
      break;
    default:
      setOpen(false);
    }
  };

  const onFilesAdd = (ids, files) => {
    setFile({});
    setFile(files[0]);
  };

  const onEditModalOpen = () => {
    console.log("s", toJS(selected));
    if (selected.file) {
      setItemToCreate("File");
      setFile(selected.file);
    }
    reset({
      name: selected.name,
    });
    setEditModalOpen(true);
  };

  const handleModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleEditModalClose = () => {
    setFile({});
    setEditModalOpen(false);
  };

  const openRemoveModal = () => {
    setRemoveModalOpen(true);
  };

  const handleOpen = (e) => {
    if (e.type === "mouseenter") {
      setOpen(true);
    }
  };

  const handleClose = (e, type) => {
    switch (type) {
    case "File":
      setItemToCreate("File");
      setSelected({});
      reset({});
      setEditModalOpen(true);
      break;
    case "Category":
      setItemToCreate("Category");
      setSelected({});
      reset({});
      setEditModalOpen(true);
      break;
    default:
      setOpen(false);
    }
  };

  const onSelect = (type, item) => {
    switch (type) {
    case "single":
      setSelected(item);

      if (selected.id === item.id) {
        setSelected({});
      }
      break;
    case "double":
      history.push({
        pathname: "/catalog",
        search: `?filter[parent]=${item.id}`,
      });
      break;
    default:
      return;
    }
  };

  const onRemove = async () => {
    setOpenLoader(true);
    const queryParams = qs.parse(location.search);
    setRemoveModalOpen(false);
    try {
      await removeCatalog(selected.id);
      viewCatalog(queryParams)
        .finally(() => {
          setOpenLoader(false);
        });
    } catch (e) {
      console.error(e);
    }
    getCatalogItem(queryParams["filter[parent]"]);
    setOpenLoader(false);
    setSelected({});
  };

  function handleClick(event, id) {
    event.preventDefault();
    history.push({
      pathname: "/catalog",
      search: `?filter[parent]=${id}`,
    });
  }

  const actions = [
    { icon: <FolderOpenSharpIcon />, name: "Category" },
    { icon: <FilePresentIcon />, name: "File" },
  ];

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 2,
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{
          mb: 2,
          mt: 1,
        }}>
          {breadCrumbsReversed.map((item, index) => {
            return (
              <div key={index}>
                {index === breadCrumbsReversed.length - 1 ? (
                  <Typography color="text.primary">{item.name}</Typography>
                ) : (
                  <Link
                    underline="hover"
                    color="inherit"
                    href={`catalog?filter[parent]=${item.id}`}
                    onClick={(e) => handleClick(e, item.id)}
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            );
          })}
        </Breadcrumbs>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {selected.id && (
        <div>
          <Button
            variant="outlined"
            startIcon={<DeleteForeverIcon />}
            onClick={openRemoveModal}
            sx={{
              marginRight: 1,
              borderColor: "#1A2327",
              color: "#1A2327",
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={onEditModalOpen}
            sx={{
              marginRight: 1,
              borderColor: "#1A2327",
              color: "#1A2327",
            }}
          >
            Edit
          </Button>
        </div>
      )}
      <div className={style.wrapper}>
        {catalogItem?.file ? (
          <div>
            <h2 className={style.fileHeadline}>{catalogItem.name}</h2>
            {catalogItem?.file.file.mimeType.includes("spreadsheetml") ? (
              <div className={style.description}>
                <FileReader data={catalogItem?.file} />
                <p className={style.descriptionFirst}>File of this type is not available for preview.</p>
                <p>In order to view this file, you should download it by clicking on the link.</p>
              </div>
            ) : (
              <iframe
                className={style.iframeWrap}
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="1000"
                height="800"
                scrolling="auto"
                src={`http://docs.google.com/viewer?url=${catalogItem?.file.url}&embedded=true`}>
              </iframe>
            )}
          </div>
        ) : (
          <div className={style.itemWrap}>
            {catalogList.map((cat) => {
              return (
                <div
                  key={cat.id}
                >
                  <CatalogItem
                    item={cat}
                    isSelected={selected.id === cat.id}
                    onSelect={onSelect}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={(e) => handleClose(e, action.name)}
          />
        ))}
      </SpeedDial>
      <Modal
        open={removeModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <p>Are you sure you want to remove this {selected.type}?</p>
          <Box sx={{
            padding: 0,
          }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleModalClose}
              sx={{
                "marginRight": "7px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
              onClick={onRemove}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={editModalOpen}
        onClose={handleEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <Box sx={{
            padding: 0,
          }}>
            <TextField
              required
              id="name"
              name="name"
              label="Name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="dense"
              {...register("name")}
              error={errors.name ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.name?.message}
            </Typography>
          </Box>
          <div className={style.ButtonWrap}>
            <Button
              sx={{
                "mt": 2,
                "width": "100%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                },
              }}
              size="medium"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {selected.id ? "Update" : `Create ${itemToCreate}`}
            </Button>
            {itemToCreate === "File" && fileError && (
              <p className={style.error}>File is required</p>
            )}
            {fileItem.id && itemToCreate === "File" ? (
              <div className={style.ReaderWrap}>
                <FileReader
                  data={fileItem}
                />
                <div
                  onClick={onRemoveFile}
                  className={style.removeItem}
                >
                  <DeleteOutlineIcon />
                </div>
              </div>
            ) : (
              <div>
                {itemToCreate === "File" && (
                  <FileUploader
                    multiple={false}
                    onFilesAdd={onFilesAdd}
                  />
                )}
                {file.progress > 0 && (<LinearProgress variant="determinate" value={file.progress} />)}
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default observer(Catalog);
