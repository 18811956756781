import { observer } from "mobx-react-lite";
import { ReactComponent as ClipIcon } from "../../assets/icons/clip.svg";
import { validateFileType } from "../../utils/functions";
import { useStores } from "../../hooks/use-stores";
import styles from "./FileUploader.module.scss";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
/* eslint-disable */
const FileUploader = ({ onFilesAdd, multiple = true }) => {
  const {
    file: { sendFile },
  } = useStores();

  const onFileAdd = async (e) => {
    const filesArr = [];
    const idsArr = [];
    
    const form = new FormData();
    const { files } = e.target;
    const filesArray = Array.from(files);
    for (const el of filesArray) {
      const validFile = validateFileType(el);
      form.append("file", validFile);
      const file = await sendFile(form);

      if (file) {
        idsArr.push(file.id);
        filesArr.push(file);
      }
    }
    onFilesAdd(idsArr, filesArr);
  };

  return (
    <>
      <div>
        <input
          accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          className={styles.uploader}
          id="product-shipment-file"
          onChange={onFileAdd}
          type="file"
          multiple={multiple}
        />
        <label htmlFor="product-shipment-file" className={styles.attach}>
          <ClipIcon />
          Attach file{multiple && "s"}
        </label>
        <p>Allowed file types: png, jpeg, pdf, xls, xlsx, jpg</p>
        <p>Maximum file size 300Mb</p>
      </div>
    </>
  );
};

export default observer(FileUploader);

FileUploader.propTypes = {
  onFilesAdd: PropTypes.func,
  multiple: PropTypes.bool,
};
