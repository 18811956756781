import PropTypes from "prop-types";
import styles from "./orderChat.module.scss";
import MessageComponent from "./Message";
import SendIcon from "@mui/icons-material/Send";
import Visibility from "@mui/icons-material/Visibility";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import * as React from "react";
import ChatFileUploader from "../FileUploader/ChatFileUploader";
import { useState, useRef } from "react";

const OrderChatWrap = ({ messages, onMessageSend }) => {
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  const uploaderNode = useRef();

  const analyzePress = (event) => {
    if (event.ctrlKey && event.key === "Enter") {
      setMessage((message) => message + "\n");
    } else if (event.key === "Enter") {
      event.preventDefault();
      onMessageSend(message);
      setMessage("");
    }
  };

  const sendMessage = async (e) => {
    // TODO fix double request with the same file id
    e.preventDefault();
    const filesInfo = uploaderNode.current.getFilesInfo();
    if (filesInfo.length > 0) {
      for (const file of filesInfo) {
        await onMessageSend(message, file);
      }
    }
    onMessageSend(message);
    uploaderNode.current.clearFilesInfo();
    setMessage("");
  };
  return (
    <div className={styles.env}>
      <div className={styles.messagesWrap}>
        {messages && messages.map((item) => {
          return (
            <div key={item.id} className={styles.messagesItem}>
              <MessageComponent
                message={item}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.formWrap}>
        <div className={styles.uploaderWrap}>
          <ChatFileUploader inputID="messenger-file-uploader" ref={uploaderNode} withCounter withoutName isMultiple />
        </div>
        <div className={styles.inputWrap}>
          <TextareaAutosize
            placeholder="Write a message..."
            aria-label="maximum height"
            className={styles.input}
            onKeyDown={analyzePress}
            onChange={handleChange}
            value={message}
            maxRows={8}
            minRows={1}
          />
        </div>
        <SendIcon
          aria-label="toggle password visibility"
          onClick={sendMessage}
          sx={{
            cursor: "pointer",
            backgroundColor: "white",
            height: "100%",
            position: "relative",
            zIndex: 100000,
            paddingBottom: 1,
          }}
        >
          <Visibility />
        </SendIcon>
      </div>
    </div>
  );
};

export default OrderChatWrap;

OrderChatWrap.propTypes = {
  messages: PropTypes.array,
  onMessageSend: PropTypes.func,
};
