import { observer } from "mobx-react-lite";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import styles from "./form.module.scss";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import FileUploader from "../FileUploader/FileUploader";
import Grid from "@mui/material/Grid";
import FileReader from "../FileReader/FileReader";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Product name required")
    .min(2, "Product name required must be at least 2 characters")
    .max(150, "Product name must not exceed 150 characters"),
  cargoSpecification: Yup.string()
    .required("Cargo specification is required")
    .min(2, "Cargo specification must be at least 2 characters")
    .max(150, "Cargo specification must not exceed 150 characters"),
  category: Yup.string()
    .required("Category is required"),
  value: Yup.string()
    .required("Value is required")
    .min(1, "Value specification must be at least 1 characters")
    .max(150, "Value specification must not exceed 150 characters"),
  item: Yup.string()
    .required("Item is required")
    .min(1, "Item specification must be at least 1 characters")
    .max(150, "Item specification must not exceed 150 characters"),
  dimensions: Yup.string()
    .required("Dimensions is required")
    .min(1, "Dimensions must be at least 1 characters")
    .max(150, "Dimensions must not exceed 150 characters"),
  kindOfGoods: Yup.string()
    .required("Kind of goods is required")
    .min(2, "Kind of goods must be at least 2 characters")
    .max(250, "Kind of goods must not exceed 250 characters"),
  commend: Yup.string()
    .notRequired()
    .max(500, "Comments must not exceed 500 characters"),
});

export const categories = [
  {
    value: "appliance",
    label: "appliance",
  },
  {
    value: "accessories_for_clothes",
    label: "accessories for clothes",
  },
  {
    value: "bags_and_shoes",
    label: "bags and shoes",
  },
  {
    value: "battery",
    label: "battery",
  },
  {
    value: "children_products",
    label: "childrens products",
  },
  {
    value: "computers_and_office",
    label: "computers and office",
  },
  {
    value: "cosmetic_and_hygiene",
    label: "cosmetics ans hygiene",
  },
  {
    value: "clothes",
    label: "clothes",
  },
  {
    value: "consumables",
    label: "consumables",
  },
  {
    value: "electronics_portable",
    label: "electronics portable",
  },
  {
    value: "accessories_for_clothes",
    label: "electronics for clothes",
  },
  {
    value: "equipment_and_tools",
    label: "equipment and tools",
  },
  {
    value: "electronics_accessory",
    label: "electronics accessory",
  },
  {
    value: "food",
    label: "food",
  },
  {
    value: "households",
    label: "households",
  },
  {
    value: "magnets",
    label: "magnets",
  },
  {
    value: "machine",
    label: "machine",
  },
  {
    value: "sports_and_hobbies",
    label: "sports and hobbies",
  },
  {
    value: "spare-parts",
    label: "spare-parts",
  },
  {
    value: "toys",
    label: "toys",
  },
  {
    value: "tea",
    label: "tea",
  },
];

const FindProduct = ({ data, onUpdate, loading, onFilesAdd }) => {
  const [isSuccess, setSuccessStatus] = useState(false);
  const [category, setCategory] = useState("");
  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
    clearErrors,
    register,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const dataObj = toJS(data);
    setCategory(data.category);
    reset(dataObj);
  }, [data]);

  const onSuccess = () => {
    setTimeout(() => setSuccessStatus(false), 4000);
    setSuccessStatus(true);
    setCategory("");
    reset();
  };

  const changeCategory = (event) => {
    clearErrors("category");
    setCategory(event.target.value);
  };

  const transformData = (data = {}) => {
    delete data.createdAt;
    delete data.files;
    delete data.id;
    delete data.user;
    for (const item in data) {
      if (!data[item]) {
        delete data[item];
      }
    }
    return data;
  };

  const formHandler = (formdata) => {
    const payload = transformData(formdata);
    onUpdate(payload);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(formHandler)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              helperText={isSubmitted && errors.name ? errors.name.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.name}
              label="Order name*"
              {...register("name")}
              name="name"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={isSubmitted && errors.cargoSpecification ? errors.cargoSpecification.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.cargoSpecification}
              label="Cargo specification*"
              {...register("cargoSpecification")}
              name="cargoSpecification"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={isSubmitted && errors.item ? errors.item.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.item}
              label="Item*"
              {...register("item")}
              name="item"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={isSubmitted && errors.category ? errors.category.message : " "}
              id="outlined-select-category"
              select
              placeholder="Select category"
              label="Select category"
              {...register("category", {
                onChange: (e) => changeCategory(e),
              })}
              variant="standard"
              error={isSubmitted && !!errors.category}
              sx={{ width: "100%" }}
              value={category}
            >
              {categories.map((option) => (
                <MenuItem sx={{ width: "100%" }} key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={isSubmitted && errors.value ? errors.value.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.value}
              label="Value*"
              {...register("value")}
              name="value"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={isSubmitted && errors.dimensions ? errors.dimensions.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.dimensions}
              label="Dimensions*"
              {...register("dimensions")}
              name="dimensions"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={isSubmitted && errors.kindOfGoods ? errors.kindOfGoods.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.kindOfGoods}
              label="Kind of goods*"
              {...register("kindOfGoods")}
              name="kindOfGoods"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextareaAutosize
              maxRows={4}
              minRows={50}
              aria-label="maximum height"
              placeholder="Add comment"
              {...register("commend")}
              className="global-textarea"
              sx={{
                width: 300,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
            >Update order</Button>
          </Grid>
        </Grid>
      </form>
      {data.files.length > 0 &&
        data.files.map((file) => (
          <div key={file.id} className={styles.buttonWrap}>
            <FileReader
              data={file}
            />
          </div>
        ))}
      {data.productDetailsFile && (
        <div className={styles.buttonWrap}>
          <FileReader
            data={data.productDetailsFile}
          />
        </div>
      )}
      {data.shipmentDetailsFileId && (
        <div className={styles.buttonWrap}>
          <FileReader
            data={data.shipmentDetailsFileId}
          />
        </div>
      )}
      <FileUploader
        onFilesAdd={onFilesAdd}
      />
    </div>
  );
};

export default observer(FindProduct);

FindProduct.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onFilesAdd: PropTypes.func,
  loading: PropTypes.bool,
};
