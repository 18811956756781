import PropTypes from "prop-types";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useStores } from "../../hooks/use-stores";

const UnapprovedClientComponent = ({ children }) => {
  const { clients } = useStores();
  const { clearSuccess } = clients;
  useEffect(() => {
    return () => {
      clearSuccess();
    };
  }, []);
  return (
    <Container maxWidth="lg">
      <h1>Unapproved Client</h1>
      {children}
    </Container>
  );
};

export default UnapprovedClientComponent;

UnapprovedClientComponent.propTypes = {
  children: PropTypes.element,
};
