import { useHistory, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStores } from "../../hooks/use-stores";
import ClientOrderList from "./ClientOrdersList";
import { observer } from "mobx-react-lite";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const qs = require("query-string");
import { ORDER_STATUS } from "../../utils/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 2,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ClientOrder = () => {
  const [editType, setEditType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [orderStatus, setOrderStatus] = useState("all");
  const [sortName, setSortName] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [activeSortField, setActiveSortField] = useState("");
  const { orders } = useStores();
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();

  const {
    getOrdersList,
    orderList,
    loading,
    totalPages,
    currentPage,
    getNextPage,
    metadata,
    paginationLimit,
    removeOrder,
    setSuccess,
    cancelOrder,
  } = orders;

  const STATIC_PAYLOAD = {
    "filter[user]": id,
  };

  const handleOpenSnack = () => setOpenSnack(true);
  const handleCloseSnack = () => setOpenSnack(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setOrderStatus(value);
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, status: value };
    history.push({ search: qs.stringify(newQueries) });
  };

  const onCloseOrder = (type, id) => {
    setEditType(type);
    setOrderId(id);
    setModalOpen(true);
  };

  const getData = () => {
    const queryParams = qs.parse(location.search);
    handleOpenSnack();
    getOrdersList({
      ...STATIC_PAYLOAD,
      ...queryParams,
    });
  };

  const onClose = async () => {
    switch (editType) {
    case "remove":
      try {
        const msg = await removeOrder(orderId);
        setMessage(msg);
        getData();
      } catch (e) {
        console.error(e);
      }
      break;
    case "cancel":
      try {
        const msg = await cancelOrder(orderId);
        setMessage(msg);
        getData();
      } catch (e) {
        console.error(e);
      }
      break;
    }
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    return () => setSuccess(null);
  }, []);

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const sort = sortValue();

    if (sort) {
      setSortName(sort);
    }

    if (queryParams.page) {
      getNextPage(+queryParams.page);
    }

    if (queryParams.status === "all") {
      delete queryParams.status;
    }

    if (queryParams.status) {
      const { status } = queryParams;
      if (orderStatus !== status) {
        setOrderStatus(status);
      }
      queryParams["filter[status]"] = status;
      delete queryParams.status;
    }
    getOrdersList({
      ...STATIC_PAYLOAD,
      ...queryParams,
    });
  }, [search]);

  const onUpdate = (data) => {
    const sort = sortValue();
    setSortName(sort);
    const queryParams = qs.parse(location.search);
    setActiveSortField(data);
    const newQueries = { ...queryParams, sortField: data, sortDirection: sort };
    history.push({ search: qs.stringify(newQueries) });
  };

  const sortValue = () => {
    const initialParams = qs.parse(location.search);
    if (initialParams.sortField && initialParams.sortDirection === "ASC") {
      return "DESC";
    }
    return "ASC";
  };

  return (
    <div>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Box sx={{ width: 250, marginBottom: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={orderStatus}
            label="Order Status"
            onChange={handleChange}
          >
            {
              ORDER_STATUS && Object.keys(ORDER_STATUS).map(function(keyName, keyIndex) {
                return (
                  <MenuItem key={keyIndex} value={keyName}>{ORDER_STATUS[keyName]}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
      </Box>
      <ClientOrderList
        orders={orderList}
        onQueryUpdate={onUpdate}
        loading={loading}
        sort={sortName}
        onCloseOrder={onCloseOrder}
        activeSortField={activeSortField}
        meta={{
          totalPages: totalPages,
          currentPage: currentPage,
          switchPage: getNextPage,
          total: metadata.total,
          limit: paginationLimit,
        }}
      />
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p>Are you sure you want to {editType} this order?</p>
          <Box sx={{
            padding: 0,
          }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleModalClose}
              sx={{
                "marginRight": "7px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
              onClick={onClose}
            >
              {editType} order
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default observer(ClientOrder);
