/* eslint-disable */
export default function(file) {
    const { url, name, type } = file;
    const downloadFile = new Blob(["\ufeff", url], { type });
    const fileLink = URL.createObjectURL(downloadFile);
    const fileName = `${name}.${type}`;
    const link = document.createElement('a');
    link.download = fileName;
    link.href = fileLink;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }