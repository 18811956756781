export default {
  createCatalog: {
    path: "/catalog",
    method: "post",
  },
  viewCatalog: {
    path: "/catalog",
    method: "get",
  },
  getCatalogItem: {
    path: "/catalog/:id",
    method: "get",
  },
  deleteCatalog: {
    path: "/catalog/:id",
    method: "delete",
  },
  updateCatalog: {
    path: "/catalog/:id",
    method: "patch",
  },
};
