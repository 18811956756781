import { observer } from "mobx-react-lite";

const Profile = observer( () => {
  return (
    <div>
      <h1>Profile</h1>
    </div>
  );
});

export default Profile;
