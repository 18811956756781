import { observer } from "mobx-react-lite";
import UsersComponent from "../../components/UsersComponent/UsersComponents";

const Clients = observer( () => {
  return (
    <div>
      <UsersComponent />
    </div>
  );
});

export default Clients;
