import LoginComponent from "../../components/Login/Login";
import Container from "@mui/material/Container";

import styles from "./Login.module.css";

const Login = () => {
  return (
    <main className={styles.wrapper}>
      <Container
        maxWidth="xs"
      >
        <LoginComponent />
      </Container>
    </main>
  );
};

export default Login;
