import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 2,
};

// @todo show plug for "no users"
const StaffHolder = ({ data, loading, meta, onRemove }) => {
  const history = useHistory();
  const [id, setId] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  const onPagination = (event, value) => {
    const params = new URLSearchParams();
    params.append("page", value);
    meta.switchPage(value);
    history.push({ search: params.toString() });
  };

  const handleOpenRemoveModal = (staffId) => {
    setId(staffId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setId(null);
  };

  const onStaffRemove = () => {
    onRemove(id);
    setModalOpen(false);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ) : (data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  <Link className="global-link" to={`/staff/${row.id}`}>
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {row.email}
                </TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleOpenRemoveModal(row.id)}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            )))
            }
          </TableBody>
        </Table>
      </TableContainer>
      {meta.total > meta.limit && <Stack spacing={2} sx={{ mt: 2 }}>
        <Pagination count={meta.totalPages} page={meta.currentPage} onChange={onPagination} />
      </Stack>}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p>Are you sure you want to remove this staff?</p>
          <Box sx={{
            padding: 0,
          }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleModalClose}
              sx={{
                "marginRight": "7px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
              onClick={onStaffRemove}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StaffHolder;

StaffHolder.propTypes = {
  data: PropTypes.array,
  currentPage: PropTypes.number,
  switchPage: PropTypes.func,
  onRemove: PropTypes.func,
  meta: PropTypes.object,
  loading: PropTypes.bool,
};
