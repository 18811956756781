import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useStores } from "../../hooks/use-stores";
import { observer } from "mobx-react-lite";
import ClientsHolder from "./components/ClientsHolder";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory, useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UsersComponent = () => {
  const { clients } = useStores();
  const location = useLocation();
  const {
    getUserList,
    success,
    removeUser,
    usersList,
    totalPages,
    currentPage,
    getNextPage,
    loading,
    metadata,
    paginationLimit,
  } = clients;

  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const handleOpenSnack = () => setOpenSnack(true);
  const handleCloseSnack = () => setOpenSnack(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    clearQuery();
  };

  const filter = () => {
    return value > 0 ? { "filter[kycStatus]": "inreview" } : null;
  };

  const clearQuery = () => {
    const params = new URLSearchParams();
    if (params.has("page")) {
      params.delete("page");
    }
    history.push({ search: params.toString() });
  };

  const queryPage = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get("page");
  };

  const handleQuery = () => {
    const query = queryPage();
    if (query) {
      getNextPage(+query, filter());
    } else {
      getUserList(filter());
    }
  };

  useEffect(() => {
    getNextPage(1, filter());
  }, [value]);

  useEffect(() => {
    handleQuery();
    if (location.state?.tab == 1) {
      handleChange("event", 1);
    };
  }, []);

  useEffect(() => {
    if (success) {
      handleOpenSnack();
      handleQuery();
    }
  }, [success]);

  const getUnapprovedNextPage = (page) => {
    getNextPage(page, {
      "filter[kycStatus]": "inreview",
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="All Clients" {...a11yProps(0)} />
          <Tab label="Unapproved clients" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>
      <TabPanel value={value} index={0}>
        {usersList.length ?
          <ClientsHolder
            data={usersList}
            loading={loading}
            onRemove={removeUser}
            type="approved"
            meta={{
              totalPages: totalPages,
              currentPage: currentPage,
              switchPage: getNextPage,
              total: metadata.total,
              limit: paginationLimit,
            }}
          /> : <p>No users</p>}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {usersList.length ?
          <ClientsHolder
            data={usersList}
            loading={loading}
            onRemove={removeUser}
            type="unapproved"
            meta={{
              totalPages: totalPages,
              currentPage: currentPage,
              switchPage: getUnapprovedNextPage,
              total: metadata.total,
              limit: paginationLimit,
            }}
          /> : <p>No unapproved users</p>}
      </TabPanel>
    </Box>
  );
};

export default observer(UsersComponent);
