import { Alert, Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { useStores } from "../../../hooks/use-stores";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

const SetStaffPassword = () => {
  const { authentication } = useStores();
  const { setAdminPassword, isLoading, error, success } = authentication;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(100, "Password must not exceed 100 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const path = window.location.pathname;
    console.log(path.split("/")[2]);
    const payload = {
      password: data.password,
      token: path.split("/")[2],
    };
    setAdminPassword(payload);
  };

  return (
    <Fragment>

      <Paper>
        <Box px={3} py={2}>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                margin="dense"
                {...register("password")}
                error={errors.password ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.password?.message}
              </Typography>
            </Grid>
          </Grid>

          <Box
            mt={0}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              sx={{
                "mt": 2,
                "width": "100%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                },
              }}
              size="medium"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >Create password
            </Button>
          </Box>
          {error ? <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
            {error}
          </Alert> : null }
          {!!success && (
            <div>
              <Alert severity="success" sx={{ width: "100%", mt: 2, mb: 2 }}>{success}</Alert>
              <Link className="global-link mt-2" to={"/login"}>Login</Link>
            </div>
          )}
        </Box>
      </Paper>
    </Fragment>
  );
};

export default observer(SetStaffPassword);
