export const ORDER_STATUS = {
  "all": "All",
  "product_details_negotiation": "Product details negotiation",
  "product_searching": "Product Searching",
  "shipping_details_negotiation": "Shipping details negotiation",
  "awaiting_payment": "Awaiting payment",
  "shipping_in_progress": "Shipping in progress",
  "completed": "Completed",
  "canceled": "Canceled",
};
