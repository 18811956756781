import FolderOpenSharpIcon from "@mui/icons-material/FolderOpenSharp";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { observer } from "mobx-react-lite";
import * as React from "react";
import style from "./catalogItem.module.scss";
import PropTypes from "prop-types";
import useDoubleClick from "use-double-click";
import { useRef } from "react";

const CatalogItem = ({ item, onSelect, isSelected }) => {
  const buttonRef = useRef();

  useDoubleClick({
    onSingleClick: () => onSelect("single", item),
    onDoubleClick: () => onSelect("double", item),
    ref: buttonRef,
    latency: 250,
  });

  const iconType = (item) => {
    switch (item.type) {
    case "category": {
      return <FolderOpenSharpIcon fontSize="large" />;
    }
    case "file": {
      return <FilePresentIcon fontSize="large" />;
    }
    default:
      return;
    }
  };

  return (
    <div
      className={`${style.itemWrap} ${isSelected ? "selected" : ""}`}
      ref={buttonRef}
    >
      {iconType(item)}
      {item.name}
    </div>
  );
};

export default observer(CatalogItem);

CatalogItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};
