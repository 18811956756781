import { observable, action, makeObservable, computed } from "mobx";
import { axios } from "../utils/axios";
import staff from "../api/endpoints/staff";

class Staff {
  staffList = []
  loading = false
  error = null
  success = null
  paginationLimit = 5
  currentPage = 1
  metadata = {}
  staffUnit = {}

  async updateStaff(staffId, payload) {
    const path = staff.updateAdmin.path;
    const method = staff.updateAdmin.method;
    const pathID = path.replace(":id", staffId);

    const data = {
      ...payload,
      "roles": [
        "ROLE_STAFF",
      ],
    };

    this.setLoading(true);
    this.setSuccess(null);
    try {
      const staff = await axios[method](pathID, data);
      this.setStaff(staff);
      this.setSuccess("Staff was successfully updated");
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async getStaff(staffId) {
    const path = staff.getStaff.path;
    const method = staff.getStaff.method;
    const pathID = path.replace(":id", staffId);

    this.setLoading(true);
    try {
      const data = await axios[method](pathID);
      this.setStaff(data);
    } catch (e) {
      console.error(e);
      this.setStaff({});
    }
    this.setLoading(false);
  }

  async createStaff(data) {
    const path = staff.createStaff.path;
    const method = staff.createStaff.method;

    const payload = {
      ...data,
      "roles": [
        "ROLE_STAFF",
      ],
    };

    this.setError(null);
    this.setSuccess(null);
    this.setLoading(true);

    try {
      await axios[method](path, payload);
      this.setSuccess("Staff was successfully created");
    } catch (e) {
      if (e.response.data && e.response.data.details) {
        const errorType = Object.keys(e.response.data.details);
        if (errorType && errorType.length) {
          this.error = errorType[0] === "email" ? "Email is already in use" : null;
        }
      } else {
        this.setError(e.message);
      }
    }
    this.setLoading(false);
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setSuccess(suc) {
    this.success = suc;
  }

  setError(error) {
    this.error = error;
  }

  getNextPage(page) {
    this.currentPage = page;
    this.getStaffList();
  }

  get totalPages() {
    if (this.metadata.total) {
      return Math.ceil(this.metadata.total / this.metadata.limit);
    }
  }

  get offset() {
    return (this.currentPage - 1) * this.paginationLimit;
  }

  async getStaffList() {
    const path = staff.staffList.path;
    const method = staff.staffList.method;
    this.setLoading(true);
    try {
      const data = await axios[method](path, {
        params: {
          "offset": this.offset,
          "limit": this.paginationLimit,
          "filter[role]": "ROLE_STAFF",
        },
      });
      const { metadata, results } = data;
      this.metadata = metadata;
      this.staffList = results;
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async removeStaff(id) {
    const path = staff.removeStaff.path;
    const method = staff.removeStaff.method;
    this.setError(null);
    this.setLoading(true);
    this.setSuccess(null);
    const pathID = path.replace(":id", id);

    try {
      await axios[method](pathID);
      this.setSuccess("Staff was successfully removed");
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
    this.setSuccess(null);
  }

  setStaff(staff) {
    this.staffUnit = staff;
  }

  get isStaffUnit() {
    return Object.keys(this.staffUnit).length > 0;
  }
}

const staffStore = new Staff();

makeObservable(staffStore, {
  metadata: observable,
  staffUnit: observable,
  staffList: observable,
  paginationLimit: observable,
  success: observable,
  error: observable,
  loading: observable,
  createStaff: action.bound,
  setError: action.bound,
  setLoading: action.bound,
  getStaffList: action.bound,
  getNextPage: action.bound,
  removeStaff: action.bound,
  getStaff: action.bound,
  setStaff: action.bound,
  setSuccess: action.bound,
  updateStaff: action.bound,
  totalPages: computed,
  isStaffUnit: computed,
});

export default staffStore;
