import PropTypes from "prop-types";
import { useRef, useEffect } from "react";

const FileReader = ({ data }) => {
  const targetNode = useRef(4);

  useEffect(() => {
    if (targetNode && targetNode.current && !targetNode.current.children.length) {
      const link = document.createElement("a");
      link.setAttribute("download", data.file.originalName);
      link.setAttribute("href", data.url);
      link.setAttribute("target", "_blank");
      link.innerHTML = data.file.originalName;
      targetNode.current.appendChild(link);
    }
  }, [data]);

  return (
    <div ref={targetNode}></div>
  );
};

export default FileReader;

FileReader.propTypes = {
  data: PropTypes.object,
};
