import { observable, action, makeObservable } from "mobx";
import { login, logout } from "../utils/axios";
import { axios } from "../utils/axios";
import staff from "../api/endpoints/staff";

class Authentication {
  isAuthenticated = false;
  isAppLoaded = false;
  isLoading = false;
  error = null;
  success = null;

  async setAdminPassword(payload) {
    const path = staff.setPassword.path;
    const method = staff.setPassword.method;

    this.setSuccess(null);
    this.setError(null);
    this.setLoading(true);
    try {
      await axios[method](path, {
        ...payload,
      });
      this.setSuccess("Password was successfully saved. Now you can login to your account");
    } catch (e) {
      console.error(e);
      this.setError(e.message);
    }
    this.setLoading(false);
  }

  async login(creds) {
    this.setLoading(true);
    this.setError(null);
    try {
      const userData = await login(creds);
      if (!userData) {
        this.setError("Wrong email or password");
      } else {
        this.setAuthenticated(true);
      }
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  setSuccess(suc) {
    this.success = suc;
  }

  setAppLoadingStatus(isAppLoaded) {
    this.isAppLoaded = isAppLoaded;
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  setError(err) {
    this.error = err;
  }

  checkIfAuthenticated() {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    if (accessToken && refreshToken) {
      this.setAuthenticated(true);
    }
  }

  // This function called when refresh token expired, from @utils/axios
  setAuthenticated(authenticated) {
    this.isAuthenticated = authenticated;
  }

  logout() {
    logout();
    this.setAuthenticated(false);
  }
}

const authentication = new Authentication();

makeObservable(authentication, {
  isAuthenticated: observable,
  success: observable,
  error: observable,
  isLoading: observable,
  isAppLoaded: observable,
  login: action.bound,
  logout: action.bound,
  checkIfAuthenticated: action.bound,
  setAuthenticated: action.bound,
  setAppLoadingStatus: action.bound,
  setError: action.bound,
  setLoading: action.bound,
  setSuccess: action.bound,
  setAdminPassword: action.bound,
});

export default authentication;
