import UnapprovedClientComponent from "../../components/Client/UnapprovedClientComponent";
import { useHistory } from "react-router-dom";
import KYCForm from "../../components/Client/KYCForm";
import { useParams } from "react-router-dom";
import { useStores } from "../../hooks/use-stores";
import React, { useEffect } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import {
  Alert,
  Snackbar,
} from "@mui/material";

const UnapprovedClient = () => {
  const { clients } = useStores();
  const { getKYCFormData, updateKYC, KYCFormData, error, loading, success, clearSuccess } = clients;
  const { id } = useParams();
  const history = useHistory();

  const [openSnack, setOpenSnack] = React.useState(false);
  const handleOpenSnack = () => setOpenSnack(true);
  const handleCloseSnack = () => {
    setOpenSnack(false);
    clearSuccess();
  };

  useEffect(() => {
    getKYCFormData(id);
  }, []);

  useEffect(() => {
    if (success) {
      handleOpenSnack();
    }
  }, [success]);

  const onApproveUser = (data) => {
    console.log(data);
    const kyc = toJS(KYCFormData);
    updateKYC(kyc.id, {
      ...data,
      "status": "confirmed",
      "establishedDate": data.establishedDate.toString(),
    });
    history.push({
      pathname: "/clients",
      state: {
        tab: 1,
      },
    });
  };

  return (
    <div>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>
      <UnapprovedClientComponent>
        <div>
          {loading ? "Loading" : (
            <>
              {error && <Alert severity="error" sx={{ "mb": 2 }}>{error}</Alert>}
              <KYCForm
                data={KYCFormData}
                onDataUpdate={onApproveUser}
                type="approve"
              />
            </>
          )}
        </div>
      </UnapprovedClientComponent>
    </div>
  );
};

export default observer(UnapprovedClient);

