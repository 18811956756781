import { observable, action, makeObservable, computed } from "mobx";
import { axios } from "../utils/axios";
import orders from "../api/endpoints/orders";

class Orders {
  orderList = []
  order = {}
  loading = false
  paginationLimit = 15
  currentPage = 1
  metadata = {}
  success = null

  async attachFiles(orderId, files) {
    const path = orders.attachFiles.path;
    const method = orders.attachFiles.method;
    const idPath = path.replace(":id", orderId);

    this.setLoading(true);
    try {
      await axios[method](idPath, {
        files,
      });
      this.setLoading(false);
      return Promise.resolve("Files was attached");
    } catch (e) {
      this.setLoading(false);
      return Promise.reject(e);
    }
  }

  async updateOrder(orderId, payload) {
    const path = orders.updateOrder.path;
    const method = orders.updateOrder.method;
    const idPath = path.replace(":id", orderId);

    this.setLoading(true);
    try {
      const order = await axios[method](idPath, {
        ...payload,
      });
      this.setOrder(order);
      this.setLoading(false);
      return Promise.resolve("Order was successfully updated");
    } catch (e) {
      this.setLoading(false);
      return Promise.reject(e);
    }
  }

  async updateOrderStatus(orderId, status) {
    const path = orders.updateOrderStatus.path;
    const method = orders.updateOrderStatus.method;
    const idPath = path.replace("order_id", orderId);

    try {
      await axios[method](idPath, {
        status,
      });
      return Promise.resolve("Status was successfully updated");
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getOrdersList(payload) {
    const path = orders.orderList.path;
    const method = orders.orderList.method;

    if (!payload.page) {
      this.setCurrentPage(1);
    }

    this.setLoading(true);
    try {
      const { results, metadata } = await axios[method](path, {
        params: {
          offset: this.offset,
          limit: this.paginationLimit,
          ...payload,
        },
      });
      this.setOrdersList(results);
      this.setMetadata(metadata);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async getOrder(orderId) {
    const path = orders.viewOrder.path;
    const method = orders.viewOrder.method;
    const idPath = path.replace(":id", orderId);

    this.setSuccess(null);
    this.setLoading(true);
    try {
      const order = await axios[method](idPath);
      this.setOrder(order);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  async removeOrder(orderId) {
    const path = orders.removeOrder.path;
    const method = orders.removeOrder.method;
    const idPath = path.replace(":id", orderId);

    this.setSuccess(null);
    this.setLoading(true);
    try {
      await axios[method](idPath);
      this.setLoading(false);
      this.setSuccess("Order was successfully removed");
      return Promise.resolve("Order was successfully removed");
    } catch (e) {
      console.error(e);
      this.setLoading(false);
      return Promise.reject(e.message);
    }
  }

  async cancelOrder(orderId) {
    const path = orders.updateOrderStatus.path;
    const method = orders.updateOrderStatus.method;
    const idPath = path.replace("order_id", orderId);

    this.setSuccess(null);
    this.setLoading(true);
    try {
      await axios[method](idPath, {
        "status": "canceled",
      });
      this.setLoading(false);
      this.setSuccess("Order was successfully cancelled");
      return Promise.resolve("Order was successfully cancelled");
    } catch (e) {
      console.error(e);
      this.setLoading(false);
      return Promise.reject(e.message);
    }
  }

  setOrder(order) {
    this.order = order;
  }

  setSuccess(suc) {
    this.success = suc;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setOrdersList(orders) {
    this.orderList = orders;
  }

  setMetadata(data) {
    this.metadata = data;
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  getNextPage(page) {
    this.setCurrentPage(page);
    // this.getOrdersList(filter);
  }

  get totalPages() {
    if (this.metadata.total) {
      return Math.ceil(this.metadata.total / this.metadata.limit);
    }
  }

  get offset() {
    return (this.currentPage - 1) * this.paginationLimit;
  }
}

const ordersStore = new Orders();

makeObservable(ordersStore, {
  orderList: observable,
  success: observable,
  loading: observable,
  order: observable,
  metadata: observable,
  getOrdersList: action.bound,
  getOrder: action.bound,
  setMetadata: action.bound,
  setLoading: action.bound,
  setOrder: action.bound,
  updateOrder: action.bound,
  attachFiles: action.bound,
  setOrdersList: action.bound,
  setCurrentPage: action.bound,
  removeOrder: action.bound,
  cancelOrder: action.bound,
  getNextPage: action.bound,
  setSuccess: action.bound,
  updateOrderStatus: action.bound,
  totalPages: computed,
  offset: computed,
});

export default ordersStore;
