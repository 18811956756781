import "./App.css";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Clients from "./views/Clients/Clients";
import Staff from "./views/Staff/Staff";
import Profile from "./views/Profile/Profile";
import EditStaffView from "./views/EditStaff/EditStaffView";
import SetPassword from "./views/SetPasswordForStaff/SetPassword";
import Client from "./views/Client/Client";
import UnapprovedClient from "./views/UnapprovedClient/UnapprovedClient";
import Order from "./views/Order/Order";
import Login from "./views/Login/Login";
import Layout from "./components/Layout/Layout";
import Orders from "./views/Orders/Orders";
import Catalog from "./views/Catalog/Catalog";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useStores } from "./hooks/use-stores";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#49B449",
      dark: "#263238",
    },
    secondary: {
      main: "#263238",
    },
    general: {
      white: "#FFFFFF",
      black: "#000000",
    },
    white: "#FFFFFF",
  },
});

const App = observer( () => {
  const newHistory = useHistory();
  const { authentication, profile } = useStores();
  const { isAuthenticated, checkIfAuthenticated, isAppLoaded, setAppLoadingStatus } = authentication;
  const { getProfile } = profile;

  useEffect(() => {
    checkIfAuthenticated();
    setAppLoadingStatus(true);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getProfile();
    }
  }, [isAuthenticated]);

  return (
    <ThemeProvider theme={theme}>
      {isAppLoaded &&<div className="App">
        <Router history={newHistory}>
          {isAuthenticated ? (
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/clients"
                  component={Clients}
                />
                <Route
                  path="/clients/approved-client/:id"
                  component={Client}
                />
                <Route
                  path="/clients/unapproved-client/:id"
                  component={UnapprovedClient}
                />
                <Route
                  path="/order/:id"
                  component={Order}
                />
                <Route
                  path="/staff/:id"
                  component={EditStaffView}
                />
                <Route
                  path="/staff"
                  component={Staff}
                />
                <Route
                  path="/profile"
                  component={Profile}
                />
                <Route
                  path="/orders"
                  component={Orders}
                />
                <Route
                  path="/catalog"
                  component={Catalog}
                />
                <Redirect to="/clients" />
              </Switch>
            </Layout>
          ) : (
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/set-password" component={SetPassword}/>
              <Redirect to="/login" />
            </Switch>
          )}
        </Router>
      </div>}
    </ThemeProvider>
  );
});

export default App;
