import styles from "./orderChat.module.scss";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ORDER_STATUS } from "../../utils/constants";
import * as React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OrderChatWrap from "./OrderChatWrap";
import FindProduct from "./FindProduct";
import ProductShipment from "./ProductShipment";
import FileReader from "../FileReader/FileReader";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
};

const OrderChat = ({ orderInfo, messages, onMessageSend, onStatusUpdate, clearChat, onUpdate, isLoading, onFilesAdd }) => {
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [orderStatus, handleOrderStatus] = useState("");
  const [value, setValue] = useState("1");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (orderInfo && orderInfo.status) {
      handleOrderStatus(orderInfo.status);
    }

    return () => {
      clearChat();
    };
  }, []);

  const handleClose = () => {
    setChangeStatusModal(false);
  };

  const openChangeStatus = () => {
    if (!orderStatus) {
      handleOrderStatus(orderInfo.status);
    }
    setChangeStatusModal(true);
  };

  const handleOrderStatusChange = (e) => {
    handleOrderStatus(e.target.value);
    onStatusUpdate(e.target.value);
    onMessageSend(`Order status was changed to ${ORDER_STATUS[e.target.value]}`);
    setChangeStatusModal(false);
  };

  return (
    <div className={styles.chatWrapper}>
      <div onClick={openChangeStatus} className={styles.orderInfoWrapper}>
        <h4 className={styles.headlineInfo}>Change order status</h4>
        <SettingsIcon
          sx={{
            marginLeft: "4px",
          }}
        />
      </div>
      <h4>Client: {orderInfo?.user?.fullName}</h4>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Chat" value="1" />
            <Tab label="Product Info" value="2" />
            <Tab label="Shipping info" value="3" />
            <Tab label="Files" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div>
            <OrderChatWrap
              messages={messages}
              onMessageSend={onMessageSend}
            />
          </div>
        </TabPanel>
        <TabPanel value="2">
          <FindProduct
            data={orderInfo}
            onUpdate={onUpdate}
            loading={isLoading}
            onFilesAdd={onFilesAdd}
          />
        </TabPanel>
        <TabPanel value="3">
          <ProductShipment
            data={orderInfo}
            onUpdate={onUpdate}
            loading={isLoading}
            onFilesAdd={onFilesAdd}
          />
        </TabPanel>
        <TabPanel value="4">
          {orderInfo.messagesFiles && (
            orderInfo.messagesFiles.map((item) => {
              return (
                <div key={item.file.id} className={styles.fileReaderWrap}>
                  <FileReader
                    data={item.file}
                  />
                  <p>{moment(item.file.createdAt).format("MMM Do YY")}</p>
                </div>
              );
            })
          )}
          {orderInfo.files && (
            orderInfo.files.map((item) => {
              return (
                <div key={item.id} className={styles.fileReaderWrap}>
                  <FileReader
                    data={item}
                  />
                  <p>{moment(item.createdAt).format("MMM Do YY")}</p>
                </div>
              );
            })
          )}
        </TabPanel>
      </TabContext>
      <Modal
        open={changeStatusModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          noValidate
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderStatus}
              label="Order Status"
              onChange={handleOrderStatusChange}
            >
              {
                ORDER_STATUS && Object.keys(ORDER_STATUS).map(function(keyName, keyIndex) {
                  return (
                    <MenuItem key={keyIndex} value={keyName}>{ORDER_STATUS[keyName]}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              marginTop: 4,
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default OrderChat;

OrderChat.propTypes = {
  orderInfo: PropTypes.object,
  onMessageSend: PropTypes.func,
  clearChat: PropTypes.func,
  onStatusUpdate: PropTypes.func,
  messages: PropTypes.array,
  onUpdate: PropTypes.func,
  onFilesAdd: PropTypes.func,
  isLoading: PropTypes.bool,
};
