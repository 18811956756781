import StaffComponent from "../../components/StaffComponent/StaffComponent";
import { observer } from "mobx-react-lite";

const Staff = observer( () => {
  return (
    <div>
      <StaffComponent />
    </div>
  );
});

export default Staff;
