import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import * as Yup from "yup";
import {
  Typography,
  TextField,
  Grid,
  Box,
  Button,
} from "@mui/material";

const HandleStaffForm = ({ onDataSend, loading, error, type }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(1, "Name must be at least 1 characters")
      .max(100, "Name must not exceed 100 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    onDataSend(data);
  };

  return (
    <Fragment>

      <Box>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="name"
              name="name"
              label="Name"
              fullWidth
              margin="dense"
              {...register("name")}
              error={errors.name ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.username?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              margin="dense"
              {...register("email")}
              error={errors.email ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.email?.message}
            </Typography>
          </Grid>
        </Grid>

        <Box
          mt={0}
          sx={{ display: "flex", justifyContent: "end", padding: 0 }}
        >
          <Button
            sx={{
              "mt": 2,
              "width": "100%",
              "&:hover": {
                backgroundColor: "#fff",
                color: "black",
                width: "100%",
              } }}
            size="medium"
            variant="contained"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >Create
          </Button>
        </Box>
        { error && (
          <Alert
            severity="error"
            sx={{ "mt": 2 }}
          >
            {error}
          </Alert>
        )}
      </Box>
    </Fragment>
  );
};

export default HandleStaffForm;

HandleStaffForm.propTypes = {
  onDataSend: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.any,
  type: PropTypes.string,
};
