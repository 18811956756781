import PropTypes from "prop-types";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { toJS } from "mobx";

const UpdateClientForm = ({ data, onRemove, onDataUpdate }) => {
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Name is required")
      .min(1, "Name must be at least 1 characters")
      .max(100, "Name must not exceed 100 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    const dataObj = toJS(data);
    reset(dataObj);
  }, [data]);

  const onSubmit = (data) => {
    onDataUpdate(data);
  };

  return (
    <div>
      <Box>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="fullName"
              name="fullName"
              label="Name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="dense"
              {...register("fullName")}
              error={errors.fullName ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.fullName?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="dense"
              {...register("email")}
              error={errors.email ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.email?.message}
            </Typography>
          </Grid>
        </Grid>

        <Box
          mt={0}
          sx={{ padding: 0 }}
        >
          <>
            <Button
              sx={{
                "mt": 2,
                "width": "100%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                } }}
              size="medium"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >Update
            </Button>
            <Button
              sx={{
                "mt": 2,
                "width": "100%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                } }}
              color="error"
              size="medium"
              variant="contained"
              onClick={onRemove}
            >Remove client
            </Button>
          </>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateClientForm;

UpdateClientForm.propTypes = {
  data: PropTypes.object,
  onRemove: PropTypes.func,
  onDataUpdate: PropTypes.func,
  onInputChange: PropTypes.func,
};
