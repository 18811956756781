import PropTypes from "prop-types";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect, useState, useRef } from "react";
import { useStores } from "../../hooks/use-stores";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { toJS } from "mobx";
import styles from "./common.module.scss";

const KYCForm = ({ data, onDataUpdate, type }) => {
  const validationSchema = Yup.object().shape({
    customerName: Yup.string()
      .required("Name and Address is required")
      .min(2, "Name and Address must be at least 2 characters")
      .max(150, "Name and Address must not exceed 150 characters"),
    constitution: Yup.string()
      .required("Constitution is required")
      .min(2, "Constitution must be at least 2 characters")
      .max(150, "Constitution must not exceed 150 characters"),
    establishedDate: Yup.string()
      .required("Date of Establishment is required"),
    pan: Yup.string()
      .required("Permanent Account No. is required")
      .min(8, "Permanent Account No. must be at least 8 characters")
      .max(8, "Permanent Account No. must not exceed 8 characters"),
    vat: Yup.string()
      .required("Value Added Tax Registration is required"),
    customerAddress: Yup.string()
      .required("This field is required")
      .min(2, "Name and address must be at least 2 characters")
      .max(150, "Name and address must not exceed 150 characters"),
    eori: Yup.string()
      .required("EORI is required")
      .matches(/[a-zA-Z]{2}[0-9]{12}/, "2 characters should be followed by 12 digits")
      .min(14, "Name must be at least 14 characters")
      .max(14, "Name must not exceed 14 characters"),
    natureOfBusiness: Yup.string()
      .required("Nature Of Business is required")
      .min(2, "Name must be at least 2 characters")
      .max(150, "Name must not exceed 150 characters"),
    directorName: Yup.string(),
  });
  const [value, setDateValue] = useState(new Date());
  const [currentType, setCurrentType] = useState("");

  const {
    clients: { setPANCompanies, getPANCompanies, PANCompanies },
  } = useStores();

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const ref = useRef(null);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const { establishedDate } = data;
      if (establishedDate) {
        setDateValue(establishedDate.toString());
      }
    }

    const dataObj = toJS(data);
    reset(dataObj);
  }, [data]);

  const onSubmit = (formdata) => {
    formdata["establishedDate"] = +moment(value).format("YYYY");
    onDataUpdate(formdata);
  };

  const delay = (function() {
    let timer = 0;
    return function(callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  const getPAN = (data, type) => {
    setCurrentType(type);
    delay(function() {
      getPANCompanies(data);
    }, 1000 );
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClose = () => {
    setPANCompanies([]);
  };

  const onSelectCompany = (cmp) => {
    const data = toJS(cmp);
    resetField("customerName", {
      defaultValue: data.title,
      keepDirty: true,
    });
    resetField("customerAddress", {
      defaultValue: data.address,
      keepDirty: true,
    });
    resetField("constitution", {
      defaultValue: data.companyType,
      keepDirty: true,
    });
    resetField("pan", {
      defaultValue: data.companyNumber,
      keepDirty: true,
    });
    resetField("establishedDate", {
      defaultValue: data.dateOfCreation.slice(0, 4),
      keepDirty: true,
    });
    setDateValue(data.dateOfCreation.slice(0, 4));
    setPANCompanies([]);
  };

  return (
    <div>
      <Box>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div className={styles.panWrap}>
              <TextField
                required
                id="name"
                InputLabelProps={{ shrink: true }}
                name="fullName"
                label="Name of the customer*"
                fullWidth
                margin="dense"
                {...register("customerName")}
                error={errors.customerName ? true : false}
                onChange={(e) => {
                  getPAN(e.target.value, "name");
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.customerName?.message}
              </Typography>
              {PANCompanies.length && currentType === "name" ? (
                <ul className={styles.panList} ref={ref}>
                  {PANCompanies.map((el, index) => {
                    return (
                      <li onClick={() => onSelectCompany(el)} key={index}>{el.title}</li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address of the customer*"
              id="customerAddress"
              {...register("customerAddress")}
              margin="dense"
              name="customerAddress"
              sx={{
                width: "100%",
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year"]}
                label="Year only"
                value={value}
                onChange={(newValue) => {
                  setDateValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} {...register("establishedDate")} helperText={null} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="vat"
              name="vat"
              InputLabelProps={{ shrink: true }}
              label="Value Added Tax Registration No. (VAT)"
              fullWidth
              margin="dense"
              {...register("vat")}
              error={errors.vat ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.vat?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={styles.panWrap}>
              <TextField
                required
                id="pan"
                name="pan"
                InputLabelProps={{ shrink: true }}
                label="Permanent Account No. (PAN)"
                fullWidth
                margin="dense"
                {...register("pan")}
                error={errors.pan ? true : false}
                onChange={(e) => {
                  getPAN(e.target.value, "pan");
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.pan?.message}
              </Typography>
              {PANCompanies.length && currentType === "pan" ? (
                <ul className={styles.panList} ref={ref}>
                  {PANCompanies.map((el, index) => {
                    return (
                      <li onClick={() => onSelectCompany(el)} key={index}>{el.title}</li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="eori"
              InputLabelProps={{ shrink: true }}
              name="eori"
              label="Economic Operators Registration and Identification number (EORI)"
              fullWidth
              margin="dense"
              {...register("eori")}
              error={errors.eori ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.eori?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="natureOfBusiness"
              name="natureOfBusiness"
              label="Nature of Business"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="dense"
              {...register("natureOfBusiness")}
              error={errors.natureOfBusiness ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.natureOfBusiness?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="directorName"
              name="directorName"
              label="Name and address of Directors"
              fullWidth
              InputLabelProps={{ shrink: true }}
              margin="dense"
              {...register("directorName")}
              error={errors.directorName ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.directorName?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="constitution"
              InputLabelProps={{ shrink: true }}
              name="constitution"
              label="Constitution (Pvt. Ltd. LTD, Partnership, Proprietorship)"
              fullWidth
              margin="dense"
              {...register("constitution")}
              error={errors.constitution ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.constitution?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Details of branch offices of the company"
              {...register("branchOffices")}
              InputLabelProps={{ shrink: true }}
              name="branchOffices"
              defaultValue={type === "create" ? "" : " "}
              margin="dense"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact person details (name, email, phone number)"
              {...register("tax")}
              InputLabelProps={{ shrink: true }}
              name="tax"
              defaultValue={type === "create" ? "" : " "}
              margin="dense"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>

        <Box
          mt={0}
          sx={{ padding: 0 }}
        >
          <>
            <Button
              sx={{
                "mt": 2,
                "width": "100%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                } }}
              size="medium"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >{type === "update" ? "Update" : "Approve"}
            </Button>
          </>
        </Box>
      </Box>
    </div>
  );
};

export default observer(KYCForm);

KYCForm.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  onRemove: PropTypes.func,
  onDataUpdate: PropTypes.func,
};
