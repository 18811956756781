import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import styles from "./form.module.scss";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FileUploader from "../FileUploader/FileUploader";
import FileReader from "../FileReader/FileReader";

const validationSchema = Yup.object().shape({
  shipperName: Yup.string()
    .required("Shipper name required")
    .min(2, "Shipper name required must be at least 2 characters")
    .max(150, "Shipper name must not exceed 150 characters"),
  shipperContactPerson: Yup.string()
    .required("Shipper contact person required")
    .min(2, "Shipper contact person required must be at least 2 characters")
    .max(150, "Shipper contact person must not exceed 150 characters"),
  shipperPhone: Yup.string()
    .required("Shipper phone person required")
    .min(2, "Shipper phone person required must be at least 2 characters")
    .max(15, "Shipper phone person must not exceed 15 characters"),
  shipperEmail: Yup.string()
    .required("Shipper email is required")
    .min(5, "Shipper email required must be at least 2 characters")
    .max(100, "Shipper email must not exceed 100 characters"),
  shipperContactDestination: Yup.string()
    .required("Shipper contact is required")
    .min(2, "Shipper contact required must be at least 2 characters")
    .max(150, "Shipper contact must not exceed 155 characters"),
  shipperPostcode: Yup.string().required("Shipper post code is required"),
  shipperCountry: Yup.string()
    .required("Shipper country is required")
    .min(5, "Shipper country required must be at least 5 characters")
    .max(155, "Shipper country must not exceed 5 characters"),
  shipperState: Yup.string()
    .required("Shipper state is required")
    .min(2, "Shipper state required must be at least 2 characters")
    .max(100, "Shipper state must not exceed 100 characters"),
  shipperCity: Yup.string()
    .required("Shipper city is required")
    .min(2, "Shipper city required must be at least 2 characters")
    .max(100, "Shipper city must not exceed 100 characters"),
  consigneeName: Yup.string()
    .required("Consignee name is required")
    .min(2, "Consignee name required must be at least 2 characters")
    .max(150, "Consignee name must not exceed 150 characters"),
  consigneeContactPerson: Yup.string()
    .required("Consignee contact person is required")
    .min(2, "Consignee contact person required must be at least 2 characters")
    .max(150, "Consignee contact person must not exceed 150 characters"),
  consigneePhone: Yup.string()
    .required("Consignee phone is required")
    .min(1, "Consignee phone required must be at least 1 characters")
    .max(15, "Consignee phone must not exceed 15 characters"),
  consigneeEmail: Yup.string()
    .required("Consignee email is required")
    .min(5, "Consignee email required must be at least 1 characters")
    .max(100, "Consignee email must not exceed 15 characters"),
  consigneeContactDestination: Yup.string()
    .required("Consignee contact destination is required")
    .min(2, "Consignee contact destination required must be at least 2 characters")
    .max(150, "Consignee contact destination must not exceed 150 characters"),
  consigneePostcode: Yup.string().required("Consignee post code is required"),
  consigneeCountry: Yup.string().required("Consignee country is required"),
  consigneeState: Yup.string()
    .required("Consignee state is required")
    .min(2, "Consignee state required must be at least 2 characters")
    .max(100, "Consignee state must not exceed 100 characters"),
  consigneeCity: Yup.string()
    .required("Consignee city is required")
    .min(2, "Consignee city required must be at least 2 characters")
    .max(100, "Consignee city must not exceed 100 characters"),
  placeOfLoading: Yup.string()
    .required("Place of loading is required")
    .min(2, "Place of loading required must be at least 2 characters")
    .max(150, "Place of loading must not exceed 150 characters"),
  placeOfDischarge: Yup.string()
    .required("Place of discharge is required")
    .min(2, "Place of discharge required must be at least 2 characters")
    .max(150, "Place of discharge must not exceed 150 characters"),
  incoterms: Yup.string()
    .required("Incoterms is required")
    .min(2, "Incoterms must be at least 2 characters")
    .max(150, "Incoterms must not exceed 150 characters"),
  commodity: Yup.string()
    .required("Commodity is required")
    .min(2, "Commodity must be at least 2 characters")
    .max(150, "Commodity must not exceed 150 characters"),
  shippingMethod: Yup.string()
    .required("Shipping method is required")
    .min(2, "Shipping method must be at least 2 characters")
    .max(150, "Shipping method must not exceed 150 characters"),
  commend: Yup.string().notRequired().max(500, "Comments must not exceed 500 characters"),
});

const ProductShipment = ({ data, loading, onFilesAdd, onUpdate }) => {
  const [shipperCountry, setShipperCountry] = useState("");
  const [consigneeCountry, setConsigneeCountry] = useState("");
  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
    register,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const changeCountry = (type, e) => {
    if (type === "shipperCountry") {
      setShipperCountry(e.target.value);
    } else {
      setConsigneeCountry(e.target.value);
    }
  };

  const transformData = (data = {}) => {
    delete data.createdAt;
    delete data.files;
    delete data.id;
    delete data.user;
    for (const item in data) {
      if (!data[item]) {
        delete data[item];
      }
    }
    return data;
  };

  const formHandler = (formdata) => {
    const payload = transformData(formdata);
    onUpdate(payload);
  };

  useEffect(() => {
    const dataObj = toJS(data);
    reset(dataObj);
    setConsigneeCountry(dataObj.consigneeCountry);
    setShipperCountry(dataObj.shipperCountry);
  }, [data]);

  const countries = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
    },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    {
      code: "AU",
      label: "Australia",
      phone: "61",
      suggested: true,
    },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    {
      code: "BA",
      label: "Bosnia and Herzegovina",
      phone: "387",
    },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    {
      code: "CA",
      label: "Canada",
      phone: "1",
      suggested: true,
    },
    {
      code: "CC",
      label: "Cocos (Keeling) Islands",
      phone: "61",
    },
    {
      code: "CD",
      label: "Congo, Democratic Republic of the",
      phone: "243",
    },
    {
      code: "CF",
      label: "Central African Republic",
      phone: "236",
    },
    {
      code: "CG",
      label: "Congo, Republic of the",
      phone: "242",
    },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    {
      code: "DE",
      label: "Germany",
      phone: "49",
      suggested: true,
    },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    {
      code: "DO",
      label: "Dominican Republic",
      phone: "1-809",
    },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    {
      code: "FK",
      label: "Falkland Islands (Malvinas)",
      phone: "500",
    },
    {
      code: "FM",
      label: "Micronesia, Federated States of",
      phone: "691",
    },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    {
      code: "FR",
      label: "France",
      phone: "33",
      suggested: true,
    },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "United Kingdom", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    {
      code: "HM",
      label: "Heard Island and McDonald Islands",
      phone: "672",
    },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    {
      code: "IO",
      label: "British Indian Ocean Territory",
      phone: "246",
    },
    { code: "IQ", label: "Iraq", phone: "964" },
    {
      code: "IR",
      label: "Iran, Islamic Republic of",
      phone: "98",
    },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    {
      code: "JP",
      label: "Japan",
      phone: "81",
      suggested: true,
    },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    {
      code: "KN",
      label: "Saint Kitts and Nevis",
      phone: "1-869",
    },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    {
      code: "LA",
      label: "Lao People's Democratic Republic",
      phone: "856",
    },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    {
      code: "MD",
      label: "Moldova, Republic of",
      phone: "373",
    },
    { code: "ME", label: "Montenegro", phone: "382" },
    {
      code: "MF",
      label: "Saint Martin (French part)",
      phone: "590",
    },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    {
      code: "MP",
      label: "Northern Mariana Islands",
      phone: "1-670",
    },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    {
      code: "PM",
      label: "Saint Pierre and Miquelon",
      phone: "508",
    },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    {
      code: "PS",
      label: "Palestine, State of",
      phone: "970",
    },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    {
      code: "SJ",
      label: "Svalbard and Jan Mayen",
      phone: "47",
    },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    {
      code: "ST",
      label: "Sao Tome and Principe",
      phone: "239",
    },
    { code: "SV", label: "El Salvador", phone: "503" },
    {
      code: "SX",
      label: "Sint Maarten (Dutch part)",
      phone: "1-721",
    },
    {
      code: "SY",
      label: "Syrian Arab Republic",
      phone: "963",
    },
    { code: "SZ", label: "Swaziland", phone: "268" },
    {
      code: "TC",
      label: "Turks and Caicos Islands",
      phone: "1-649",
    },
    { code: "TD", label: "Chad", phone: "235" },
    {
      code: "TF",
      label: "French Southern Territories",
      phone: "262",
    },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    {
      code: "TT",
      label: "Trinidad and Tobago",
      phone: "1-868",
    },
    { code: "TV", label: "Tuvalu", phone: "688" },
    {
      code: "TW",
      label: "Taiwan, Province of China",
      phone: "886",
    },
    {
      code: "TZ",
      label: "United Republic of Tanzania",
      phone: "255",
    },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
    },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    {
      code: "VA",
      label: "Holy See (Vatican City State)",
      phone: "379",
    },
    {
      code: "VC",
      label: "Saint Vincent and the Grenadines",
      phone: "1-784",
    },
    { code: "VE", label: "Venezuela", phone: "58" },
    {
      code: "VG",
      label: "British Virgin Islands",
      phone: "1-284",
    },
    {
      code: "VI",
      label: "US Virgin Islands",
      phone: "1-340",
    },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
  ];

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(formHandler)}>
        <div>
          <h3>Shipper details</h3>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperName ? errors.shipperName.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperName}
              label="Shipper name*"
              {...register("shipperName")}
              name="shipperName"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperContactPerson ? errors.shipperContactPerson.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperContactPerson}
              label="Contact person*"
              {...register("shipperContactPerson")}
              name="shipperContactPerson"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperContactDestination ? errors.shipperContactDestination.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperContactDestination}
              label="Contact destination*"
              {...register("shipperContactDestination")}
              name="shipperContactDestination"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperEmail ? errors.shipperEmail.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperEmail}
              label="Email*"
              {...register("shipperEmail")}
              name="shipperEmail"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperCity ? errors.shipperCity.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperCity}
              label="Shipper City*"
              {...register("shipperCity")}
              name="shipperCity"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperPhone ? errors.shipperPhone.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperPhone}
              label="Phone*"
              {...register("shipperPhone")}
              name="shipperPhone"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperPostcode ? errors.shipperPostcode.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperPostcode}
              label="Postcode*"
              {...register("shipperPostcode")}
              name="shipperPostcode"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={isSubmitted && !!errors.category}
              id="outlined-select-category"
              placeholder="Select shipper country"
              {...register("shipperCountry")}
              onChange={(e) => changeCountry("shipperCountry", e)}
              label="Select shipper country"
              sx={{ width: "100%" }}
              variant="filled"
              value={shipperCountry}
              select>
              {countries.map((option) => (
                <MenuItem sx={{ width: "100%" }} key={option.label} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shipperState ? errors.shipperState.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shipperState}
              label="State*"
              {...register("shipperState")}
              name="shipperState"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        <div>
          <h3>Consignee details</h3>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneeName ? errors.consigneeName.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneeName}
              label="Shipper name*"
              {...register("consigneeName")}
              name="consigneeName"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneeContactPerson ? errors.consigneeContactPerson.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneeContactPerson}
              label="Contact person*"
              {...register("consigneeContactPerson")}
              name="consigneeContactPerson"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneePhone ? errors.consigneePhone.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneePhone}
              label="Phone*"
              {...register("consigneePhone")}
              name="consigneePhone"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneeEmail ? errors.consigneeEmail.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneeEmail}
              label="Email*"
              {...register("consigneeEmail")}
              name="consigneeEmail"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneeContactDestination ? errors.consigneeContactDestination.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneeContactDestination}
              label="Contact destination*"
              {...register("consigneeContactDestination")}
              name="consigneeContactDestination"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneePostcode ? errors.consigneePostcode.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneePostcode}
              label="Postcode*"
              {...register("consigneePostcode")}
              name="consigneePostcode"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={isSubmitted && !!errors.category}
              id="outlined-select-category"
              placeholder="Select consignee country"
              {...register("consigneeCountry")}
              onChange={(e) => changeCountry("consigneeCountry", e)}
              label="Select consignee country"
              variant="filled"
              value={consigneeCountry}
              sx={{
                width: "100%",
              }}
              select>
              {countries.map((option) => (
                <MenuItem sx={{ width: "100%" }} key={option.label} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneeState ? errors.consigneeState.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneeState}
              label="State*"
              {...register("consigneeState")}
              name="consigneeState"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.consigneeCity ? errors.consigneeCity.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.consigneeCity}
              label="City*"
              {...register("consigneeCity")}
              name="consigneeCity"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        <div>
          <h3>Cargo details</h3>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.placeOfLoading ? errors.placeOfLoading.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.placeOfLoading}
              label="Place of loading*"
              {...register("placeOfLoading")}
              name="placeOfLoading"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.placeOfDischarge ? errors.placeOfDischarge.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.placeOfDischarge}
              label="Place of discharge*"
              {...register("placeOfDischarge")}
              name="placeOfDischarge"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.incoterms ? errors.incoterms.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.incoterms}
              label="Incoterms*"
              {...register("incoterms")}
              name="incoterms"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.commodity ? errors.commodity.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.commodity}
              label="Commodity*"
              {...register("commodity")}
              name="commodity"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={isSubmitted && errors.shippingMethod ? errors.shippingMethod.message : " "}
              color={`${isSubmitted ? "success" : "grey"}`}
              error={isSubmitted && !!errors.shippingMethod}
              label="Shipping method*"
              {...register("shippingMethod")}
              name="shippingMethod"
              variant="filled"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextareaAutosize
              maxRows={4}
              minRows={50}
              aria-label="maximum height"
              placeholder="Add comment"
              {...register("commend")}
              maxLength={500}
              className="global-textarea"
            />
          </Grid>
        </Grid>
        <Button variant="contained" type="submit" disabled={loading}>
          Update order
        </Button>
      </form>
      {data.files.length > 0 &&
        data.files.map((file) => (
          <div key={file.id} className={styles.buttonWrap}>
            <FileReader data={file} />
          </div>
        ))}
      {data.productDetailsFile && (
        <div className={styles.buttonWrap}>
          <FileReader data={data.productDetailsFile} />
        </div>
      )}
      {data.shipmentDetailsFileId && (
        <div className={styles.buttonWrap}>
          <FileReader data={data.shipmentDetailsFileId} />
        </div>
      )}
      <FileUploader onFilesAdd={onFilesAdd} />
    </div>
  );
};

export default observer(ProductShipment);

ProductShipment.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onFilesAdd: PropTypes.func,
  onUpdate: PropTypes.func,
};
