import { observable, action, makeObservable, computed } from "mobx";
import { axios } from "../utils/axios";
import profile from "../api/endpoints/profile";

class Profile {
  profile = null

  async getProfile() {
    const path = profile.userList.path;
    const method = profile.userList.method;

    try {
      this.profile = await axios[method](path);
    } catch (e) {
      console.error(e);
    }
  }

  get isAdmin() {
    if (this.profile) {
      return this.profile["roles"][0] === "ROLE_ADMIN";
    }
  }
}

const profileStore = new Profile();

makeObservable(profileStore, {
  profile: observable,
  getProfile: action.bound,
  isAdmin: computed,
});

export default profileStore;
