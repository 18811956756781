import { observable, action, makeObservable } from "mobx";
import { axios } from "../utils/axios";
import file from "../api/endpoints/file";
import React from "react";

/* eslint-disable */

class File {
  fileId = null
  progress = 0

  async sendFile(form) {
    const path = file.fileUpload.path;
    const method = file.fileUpload.method;
    try {
      return await axios[method](path, form, {
        onUploadProgress: (progressEvent) => {
          this.setProgress((progressEvent.loaded / progressEvent.total) * 50);
        },
        onDownloadProgress: (progressEvent) => {
          this.setProgress(50 + (progressEvent.loaded / progressEvent.total) * 50);
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  setProgress(value) {
    if(value == 'Infinity') {
      this.progress = 100;
    } else this.progress = value;
  }
}

const fileStore = new File();

makeObservable(fileStore, {
  fileId: observable,
  progress: observable,
  sendFile: action.bound,
});

export default fileStore;
