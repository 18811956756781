import { useParams } from "react-router-dom";
import HandleEditStaffForm from "./HandleEditStaffForm";
import Container from "@mui/material/Container";
import { useStores } from "../../../hooks/use-stores";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

const EditStaff = () => {
  const { id } = useParams();
  const { staff } = useStores();
  const { getStaff, staffUnit, loading, updateStaff, removeStaff, success, error, setSuccess } = staff;

  useEffect(() => {
    getStaff(id);
  }, []);

  useEffect(() => {
    if (success) {
      getStaff(id);
    }
  }, [success]);

  const handleUpdateStaff = (payload) => {
    updateStaff(id, payload);
  };

  // @todo add preloader
  return (
    <div>
      <Container
        maxWidth="md"
      >
        <h1>Edit staff</h1>
        {loading ? "Data is loading" : (
          <HandleEditStaffForm
            data={staffUnit}
            type="update"
            onDataSend={handleUpdateStaff}
            onDataRemove={removeStaff}
            success={success}
            error={error}
            loading={loading}
            clearSuccess={setSuccess}
          />
        )}
      </Container>
    </div>
  );
};

export default observer(EditStaff);
