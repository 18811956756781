import { observable, action, makeObservable, computed } from "mobx";
import { axios } from "../utils/axios";
import catalog from "../api/endpoints/catalog";

class Catalog {
  catalogList = []
  catalogItem = {}
  breadCrumbs = []

  async createCatalog(id, payload) {
    const path = catalog.createCatalog.path;
    const method = catalog.createCatalog.method;

    try {
      const data = await axios[method](path, {
        ...payload,
      });
      return Promise.resolve(data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e.message);
    }
  }

  async removeCatalog(id) {
    const path = catalog.deleteCatalog.path;
    const method = catalog.deleteCatalog.method;
    const idPath = path.replace(":id", id);

    try {
      await axios[method](idPath);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  async updateCatalog(id, payload) {
    const path = catalog.updateCatalog.path;
    const method = catalog.updateCatalog.method;
    const idPath = path.replace(":id", id);

    try {
      await axios[method](idPath, {
        ...payload,
      });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  async getCatalogItem(id) {
    const path = catalog.getCatalogItem.path;
    const method = catalog.getCatalogItem.method;
    const idPath = path.replace(":id", id);

    try {
      const data = await axios[method](idPath);
      this.setCatalogItem(data);
      this.clearBreadcrumbs();
      this.handleBreadcrumbItems(data);
      return Promise.resolve(data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e.message);
    }
  }

  async viewCatalog(query) {
    const path = catalog.viewCatalog.path;
    const method = catalog.viewCatalog.method;

    try {
      const data = await axios[method](path, {
        params: {
          ...query,
          "limit": 100000,
        },
      });
      this.setCatalogList(data.results);
      return Promise.resolve(data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e.message);
    }
  }

  setCatalogList(catalogList) {
    this.catalogList = catalogList;
  }

  handleBreadcrumbItems(data = {}) {
    this.breadCrumbs.push({
      name: data.name,
      id: data.id,
    });
    if (!data.parent) return;
    this.handleBreadcrumbItems(data.parent);
  }

  setCatalogItem(item) {
    this.catalogItem = item;
  }

  clearBreadcrumbs() {
    this.breadCrumbs = [];
  }

  get breadCrumbsReversed() {
    const copiedArr = [...this.breadCrumbs];
    return copiedArr.reverse();
  }
}

const catalogStore = new Catalog();

makeObservable(catalogStore, {
  catalogList: observable,
  catalogItem: observable,
  breadCrumbs: observable,
  setCatalogList: action.bound,
  clearBreadcrumbs: action.bound,
  handleBreadcrumbItems: action.bound,
  getCatalogItem: action.bound,
  viewCatalog: action.bound,
  setCatalogItem: action.bound,
  breadCrumbsReversed: computed,
});

export default catalogStore;
