import ApprovedClient from "../../components/Client/ApprovedClient";
import { useParams } from "react-router-dom";
import { useStores } from "../../hooks/use-stores";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Alert, Box, Button, Modal, Snackbar, Grid, Tabs } from "@mui/material";
import UpdateClientForm from "../../components/Client/UpdateClientForm";
import ClientOrder from "../../components/Client/ClientOrder";
import KYCForm from "../../components/Client/KYCForm";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import styles from "./client.module.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 2,
};

const Client = () => {
  const { clients } = useStores();
  const { getUser, user, loading, removeUser, success, clearSuccess, updateUser, getKYCFormData, updateKYC, KYCFormData, error } = clients;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();
  useEffect(() => {
    getUser(id);
    getKYCFormData(id);

    return () => {
      clearSuccess();
    };
  }, []);

  const handleOpenSnack = () => setOpenSnack(true);
  const handleCloseSnack = () => {
    setOpenSnack(false);
    clearSuccess();
  };

  useEffect(() => {
    if (success) {
      handleModalClose();
      handleOpenSnack();
      getUser(id);
    }
  }, [success]);

  const onUpdateKYC = (payload) => {
    payload.establishedDate = payload.establishedDate.toString();
    updateKYC(payload.id, sanitizePayload(payload), true);
  };

  const sanitizePayload = (data) => {
    const dataCopy = { ...data };

    for (const y in dataCopy) {
      if (!dataCopy[y]) {
        delete dataCopy[y];
      }
    }

    return dataCopy;
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onRemove = () => {
    setModalOpen(true);
  };

  const onUserRemove = () => {
    removeUser(id);
  };

  const onUpdate = (data) => {
    updateUser(id, data);
  };

  // @todo Change order of alert -> component
  return (
    <div>
      <ApprovedClient>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Client details" {...a11yProps(0)} />
          <Tab label="Client orders" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className={styles.status}>Status: <span className={KYCFormData.status === "confirmed" ? "approved" : "unapproved"}>{KYCFormData.status === "confirmed" ? "Approved" : "Unapproved"}</span></h3>
            </Grid>
            <Grid item xs={4}>
              {loading ? "loading" :
                <UpdateClientForm
                  data={user}
                  onRemove={onRemove}
                  onDataUpdate={onUpdate}
                />}
            </Grid>
            <Grid item xs={8}>
              {error && <Alert severity="error" sx={{ "mb": 2 }}>{error}</Alert>}
              {loading ? "loading" :
                <KYCForm
                  data={KYCFormData}
                  onDataUpdate={onUpdateKYC}
                  type="update"
                />
              }
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ClientOrder />
        </TabPanel>
      </ApprovedClient>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p>Are you sure you want to remove this client?</p>
          <Box sx={{
            padding: 0,
          }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleModalClose}
              sx={{
                "marginRight": "7px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },
              }}
              onClick={onUserRemove}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(Client);
