import authentication from "./authentication";
import clients from "./clients";
import profile from "./profile";
import staff from "./staff";
import orders from "./orders";
import messages from "./messages";
import file from "./file";
import catalog from "./catalog";

export default {
  authentication,
  clients,
  profile,
  staff,
  orders,
  file,
  messages,
  catalog,
};
