import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HandleStaffForm from "./components/HandleStaffForm";
import StaffHolder from "./components/StaffHolder";
import { useStores } from "../../hooks/use-stores";
import { observer } from "mobx-react-lite";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StaffComponent = () => {
  const { staff } = useStores();
  const {
    createStaff,
    loading,
    error,
    success,
    getStaffList,
    staffList,
    totalPages,
    currentPage,
    getNextPage,
    paginationLimit,
    metadata,
    removeStaff,
    setSuccess,
  } = staff;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSnack, setOpenSnack] = React.useState(false);
  const handleOpenSnack = () => setOpenSnack(true);
  const handleCloseSnack = () => setOpenSnack(false);

  const queryPage = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get("page");
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      handleOpenSnack();
      handleQuery();
    }
  }, [success]);

  const handleQuery = () => {
    const query = queryPage();
    if (query) {
      getNextPage(+query);
    } else {
      getStaffList();
    }
  };

  useEffect(() => {
    handleQuery();
    return () => setSuccess(null);
  }, []);

  return (
    <div>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>
      <div>
        <Button
          onClick={handleOpen}
          sx={{
            "mb": 2,
            "&:hover": {
              backgroundColor: "#fff",
              color: "black",
            } }}
          variant="contained">
          Create new staff
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <HandleStaffForm
            type="create"
            onDataSend={createStaff}
            loading={loading}
            error={error}
          />
        </Box>
      </Modal>
      {staffList.length || loading ? <StaffHolder
        data={staffList}
        loading={loading}
        onRemove={removeStaff}
        meta={{
          total: metadata.total,
          totalPages: totalPages,
          currentPage: currentPage,
          switchPage: getNextPage,
          limit: paginationLimit,
        }}
      /> : <p>No Staff created yet</p> }
    </div>
  );
};

export default observer(StaffComponent);
