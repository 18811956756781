export default {
  messagesList: {
    path: "/message",
    method: "get",
  },
  creatMessage: {
    path: "/message",
    method: "post",
  },
  notifications: {
    path: "/message/notifications",
    method: "get",
  },
  bulkRead: {
    path: "/message/bulk-read",
    method: "post",
  },
};
