export default {
  staffList: {
    path: "/user-management/admin",
    method: "get",
  },
  createStaff: {
    path: "user-management/admin",
    method: "post",
  },
  removeStaff: {
    path: "/user-management/admin/:id",
    method: "delete",
  },
  setPassword: {
    path: "/user-management/common/set-password",
    method: "post",
  },
  getStaff: {
    path: "/user-management/admin/:id",
    method: "get",
  },
  updateAdmin: {
    path: "/user-management/admin/:id",
    method: "patch",
  },
};
