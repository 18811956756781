import Container from "@mui/material/Container";
import SetStaffPassword from "../../components/StaffComponent/components/SetStaffPassword";

import styles from "./Password.module.css";

const SetPassword = () => {
  return (
    <main className={styles.wrapper}>
      <Container
        maxWidth="xs"
      >
        <SetStaffPassword />
      </Container>
    </main>
  );
};

export default SetPassword;
