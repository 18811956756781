import PropTypes from "prop-types";
const ApprovedClient = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  );
};

export default ApprovedClient;

ApprovedClient.propTypes = {
  children: PropTypes.any,
};
