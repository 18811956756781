import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import { Link, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import CancelIcon from "@mui/icons-material/Cancel";
const qs = require("query-string");
import { ORDER_STATUS } from "../../utils/constants";

const ClientOrderList = ({ orders, onQueryUpdate, activeSortField, loading, meta, sort, onCloseOrder }) => {
  const history = useHistory();
  const createSortHandler = (property) => () => {
    onQueryUpdate(property);
  };

  const onPagination = (event, value) => {
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, page: value };
    history.push({ search: qs.stringify(newQueries) });
    meta.switchPage(value);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th" align="center">
                <TableSortLabel
                  active={activeSortField === "name"}
                  direction={sort === "ASC" ? "asc" : "desc"}
                  component="span"
                  onClick={createSortHandler("name")}
                >
                  Order name
                </TableSortLabel>
              </TableCell>
              <TableCell component="th" align="center">
                <TableSortLabel
                  active={activeSortField === "status"}
                  direction={sort === "ASC" ? "asc" : "desc"}
                  component="span"
                  onClick={createSortHandler("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell component="th" align="center">
                Id
              </TableCell>
              <TableCell component="th" align="center">
                Client
              </TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{
            loading ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ) : (orders.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row" align="center">
                  <Link className="global-link" to={`/order/${row.id}`}>
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {ORDER_STATUS && ORDER_STATUS[row.status]}
                </TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">{row.user?.fullName}</TableCell>
                <TableCell align="center">
                  <div>
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => onCloseOrder("remove", row.id)}
                    >
                      Remove Order
                    </Button>
                  </div>
                  <div>
                    <Button
                      startIcon={<CancelIcon />}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => onCloseOrder("cancel", row.id)}
                      sx={{
                        "mt": 2,
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "black",
                        },
                      }}
                    >
                      Cancel Order
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            )))
          }
          </TableBody>
        </Table>
      </TableContainer>
      {meta.total > meta.limit && <Stack spacing={2} sx={{ mt: 2 }}>
        <Pagination count={meta.totalPages} page={meta.currentPage} onChange={onPagination} />
      </Stack>}
    </div>
  );
};

export default ClientOrderList;

ClientOrderList.propTypes = {
  orders: PropTypes.array,
  onQueryUpdate: PropTypes.func,
  onCloseOrder: PropTypes.func,
  meta: PropTypes.object,
  loading: PropTypes.bool,
  sort: PropTypes.string,
  activeSortField: PropTypes.string,
};
