import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStores } from "../../hooks/use-stores";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import {
  Alert,
  Typography,
  TextField,
  Grid,
  Box,
  Paper,
  Button,
} from "@mui/material";

const Login = observer(() => {
  const { authentication } = useStores();
  const { login, isLoading, error } = authentication;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const payload = {
      username: data.email,
      password: data.password,
    };
    login(payload);
  };

  return (
    <Fragment>

      <Paper>
        <Box px={3} py={2}>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                margin="dense"
                {...register("email")}
                error={errors.email ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.email?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                margin="dense"
                {...register("password")}
                error={errors.password ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.password?.message}
              </Typography>
            </Grid>
          </Grid>

          <Box
            mt={0}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              sx={{
                "mt": 2,
                "width": "100%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                },
              }}
              size="medium"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >Login
            </Button>
          </Box>
          {error ? <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
            {error}
          </Alert> : null }
        </Box>
      </Paper>
    </Fragment>
  );
});

export default Login;
