import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import moment from "moment";
import Typography from "@mui/material/Typography";
import FileReader from "../FileReader/FileReader";

const Message = ({ message }) => {
  return (
    <div className={`${message.user && "client-item"}`}>
      <Card sx={{ maxWidth: 900, padding: 1.5, overflowWrap: "anywhere", width: "40%" }}>
        {(message.user && message.user.id) &&
          <Typography variant="body2" color="text.secondary">
            {message.user.fullName}
          </Typography>}
        <Typography gutterBottom variant="h6" component="div">
          {message.file ? (
            <div>
              <FileReader
                data={message.file}
              />
            </div>
          ) : (
            message.message
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {moment(message.createdAt).format("lll")}
        </Typography>
      </Card>
    </div>
  );
};

export default Message;

Message.propTypes = {
  message: PropTypes.object,
};
